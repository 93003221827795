
export const checkUserTarifLocal = (authData : any) : boolean => {
   if(authData && authData?.tarif >= 2 && authData.tarif <= 5) {
      if(authData.testMonth === null)
         return false;

      const now = new Date();
      // console.log(new Date(authData.testMonth).valueOf() - now.valueOf())
      if(new Date(authData.testMonth).valueOf() - now.valueOf() > 0) {
         return true;
      }
      return false;
   }
   return false;
}
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { setTestData5 } from "../../../redux/slices/testSlice";
import { setConfirm } from "../../../redux/slices/confirmSlice";

import { getPanList2, PanList } from "../../../helpers/getSelectPans";
import { BASE_URL, BASE_URL_OFFICE, selectPans } from "../../../constants";

import '../../../customStyles/dashstyle.css'
import { RootState } from "../../../redux/store";


type Params = {
   fiveId : string
}
export const TestOffice = () => {
   const [loading, setLoading] = useState<boolean>(false);
   const [error, setError] = useState<string | null>(null)
   const [pan1, setPan1] = useState<number>(0);
   const [pan2, setPan2] = useState<number>(0);
   const [panList2, setPanList2] = useState<PanList[] | null>(null)

   const authUserId = useSelector((state : RootState) => state.auth.data?.userId);

   const { fiveId } = useParams<Params>();

   const dispatch = useDispatch();
   const history = useHistory();



   const changePanHandler1 = (pan1_id : number) => {
      setPan1(pan1_id);
      setPanList2(getPanList2(pan1_id))
   }
   
   const changePanHandler2 = (pan2_value : number) => {
      setPan2(pan2_value);

      if (pan2_value === 0) 
         document.getElementById("gobuttondiv")?.setAttribute('style', 'display: none');
      else 
         document.getElementById("gobuttondiv")?.setAttribute('style', 'display: block');
   }


   const startHandler = async () => {
      setLoading(true);

      try {
         await fetch(BASE_URL_OFFICE + '/api/office/build', {
            method: 'POST',
            headers: {
               'Accept': 'application/json', 'Content-Type': 'application/json'
            },
            body: JSON.stringify({
               // "userId": "ade7a1c5-2da0-4362-818e-1e003256eda1",
               "userId": authUserId, 
               "fiveId": parseInt(fiveId),
               "pan1": pan1,
               "pan2": pan2
            })
         })
         .then(response => {
            if(response.ok) return response.json();
            throw new Error('*** Error startHandler() Response not OK ***');
         })
         .then(result => {
            setLoading(false);

            console.log(result);

            if(!result || (typeof result === 'string')){
               console.log(result);
               throw new Error('*** Error startHandler() Result is null or string ***')
            }
            else {
               if(dispatch(setTestData5(result))){
                  sessionStorage.removeItem('storeTime'); // для корректной работы таймера
                  dispatch(setConfirm(true));
                  history.push(`/Auth/OfficeTesting/${result.id}`);
                  
                  window.location.reload(); // для корректной работы таймера
               }
            }
         })
      }
      catch(e) {
         setLoading(false);
         // alert('Қателік кетті!')
         console.log(e);
         setError(`${e}`)
      }
   }

   return(
      <main className="ttr-wrapper">
         <div className="container-fluid">
            <div className="db-breadcrumb" style={{display: 'flex', marginBottom: '30px'}}>
               <h4 className="breadcrumb-title">Пән таңдау</h4>
            </div>

            <div style={{textAlign: 'center'}}>
               <p className="pan">Қазақстан тарихы</p>
               <p className="pan">Оқу Сауаттылығы</p>
               <p className="pan">Математикалық Сауаттылық</p>
               <hr />
               <div className="form-group">
                  <select id="pan1" className="form-control sele" onChange={(e) => changePanHandler1(parseInt(e.target.value))}>
                     <option value={0}>Пән 1</option>
                     {selectPans.map(item => {
                        if(item.id !== 4 && item.id !== 6 && item.id !== 7 && item.id !== 19){
                           return (
                              <option key={(item.id).toString()} value={item.id}>{item.panName}</option>
                           )
                        }
                     })}
                  </select>
               </div>
               {/* <br /> */}
               <div className="form-group">
                  <select id="pan2" className="form-control sele" onChange={(e) => changePanHandler2(parseInt(e.target.value))}>
                     <option value={0}>Пән 2</option>
                     {panList2 && panList2.map(item => (
                        <option key={item.panName} value={item.id}>
                           {item.panName}
                        </option>
                     ))}
                  </select>
               </div>
               <br />
               <div className="form-group" style={{display: 'none'}} id="gobuttondiv">
                  <button onClick={() => startHandler()} id="gobutton" className="btn" style={{backgroundColor: '#4c1864', color: 'white'}} disabled={loading}>
                     {loading ? 'Күте тұрыңыз...' : 'Бастау'}
                  </button>
               </div>
               {error && <div style={{color: 'red'}}>{error}</div>}
            </div>
         </div>
      </main>
   )
}
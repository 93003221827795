import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { signOut } from "../../redux/slices/authSlice";
import { AppDispatch, RootState } from "../../redux/store";

export const Header = () => {
  const auth = useSelector((state: RootState) => state.auth.data);
  const timer = useSelector((state: RootState) => state.timer);

  const dispatch = useDispatch<AppDispatch>();

  return (
      <header className="ttr-header" style={{ zIndex: 1000 }}>
         <div className="ttr-header-wrapper">
         <div className="ttr-toggle-sidebar ttr-material-button">
            <i className="ti-close ttr-open-icon"></i>
            <i className="ti-menu ttr-close-icon"></i>
         </div>

         <div className="ttr-header-right ttr-with-seperator">
            <ul className="ttr-header-navigation">
               <li>
               {timer.isRunning && (
                  <a
                     id="time-path"
                     className="ttr-material-button ttr-submenu-toggle"
                     style={{ color: "white", fontWeight: "200 !important" }}
                  >
                     <span className="time_pretext">Қалған уақыт: </span>
                     {timer.time}
                  </a>
               )}
               </li>
               <li>
               <a href="#" className="ttr-material-button ttr-submenu-toggle">
                  {auth?.userName}
               </a>
               <div className="ttr-header-submenu">
                  <ul>
                     <li>
                     <Link to="/Auth/Account">Жеке кабинет</Link>
                     </li>
                     <li>
                     <Link to="/Auth/Profil">Сабақтар</Link>
                     </li>
                     <li>
                     <a href="" onClick={() => dispatch(signOut())}>
                        Шығу
                     </a>
                     </li>
                  </ul>
               </div>
               </li>
            </ul>
         </div>
         </div>
      </header>
  );
};

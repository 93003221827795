import { createSlice } from "@reduxjs/toolkit";
import { ITest, ITest5, ITestErrorWork, ITestErrorWork5, ITestErrorWorkNuska, ITestNuska, ITestTeacher } from "../../helpers/models";


interface TestProps {
   data: ITest
   data5: ITest5
   dataNuska: ITestNuska

   dataErrorWork: ITestErrorWork
   dataErrorWork5: ITestErrorWork5
   dataErrorWorkNuska: ITestNuska

   dataTeacher: ITestTeacher 
   dataErrorWorkTeacher: ITestErrorWork
}


const storedData = sessionStorage.getItem('testData');
const storedData5 = sessionStorage.getItem('testData5');
const storedDataNuska = sessionStorage.getItem('testDataNuska');
const storedDataTeacher = sessionStorage.getItem('testDataTeacher');

const storedDataErrorWork = sessionStorage.getItem('dataErrorWork');
const storedDataErrorWork5 = sessionStorage.getItem('dataErrorWork5');
const storedDataErrorWorkNuska = sessionStorage.getItem('dataErrorWorkNuska');
const storedDataErrorWorkTeacher = sessionStorage.getItem('dataErrorWorkTeacher');

const initialState : TestProps = {
   data: typeof storedData === 'string' ? JSON.parse(storedData) : null,
   data5: typeof storedData5 === 'string' ? JSON.parse(storedData5) : null,
   dataNuska: typeof storedDataNuska === 'string' ? JSON.parse(storedDataNuska) : null,
   dataTeacher: typeof storedDataTeacher === 'string' ? JSON.parse(storedDataTeacher) : null,

   dataErrorWork: typeof storedDataErrorWork === 'string' ? JSON.parse(storedDataErrorWork) : null,
   dataErrorWork5: typeof storedDataErrorWork5 === 'string' ? JSON.parse(storedDataErrorWork5) : null,
   dataErrorWorkNuska: typeof storedDataErrorWorkNuska === 'string' ? JSON.parse(storedDataErrorWorkNuska) : null,
   dataErrorWorkTeacher: typeof storedDataErrorWorkTeacher === 'string' ? JSON.parse(storedDataErrorWorkTeacher) : null,
}


export const testSlice = createSlice({
   name: 'test',
   initialState,
   reducers: {
      setTestData: (state, action) => {
         state.data = action.payload;
         sessionStorage.setItem('testData', JSON.stringify(state.data));
      },
      setTestData5: (state, action) => {
         state.data5 = action.payload;
         sessionStorage.setItem('testData5', JSON.stringify(state.data5));
      },
      setTestDataNuska: (state, action) => {
         state.dataNuska = action.payload;
         sessionStorage.setItem('testDataNuska', JSON.stringify(state.dataNuska));
      },
      setTestDataTeacher: (state, action) => {
         state.dataTeacher = action.payload;
         sessionStorage.setItem('testDataTeacher', JSON.stringify(state.dataTeacher));
      },


      setErrorWorkData: (state, action) => {
         state.dataErrorWork = action.payload;
         sessionStorage.setItem('dataErrorWork', JSON.stringify(state.dataErrorWork))
      },
      setErrorWorkData5: (state, action) => {
         state.dataErrorWork5 = action.payload;
         sessionStorage.setItem('dataErrorWork5', JSON.stringify(state.dataErrorWork5))
      },
      setErrorWorkDataNuska: (state, action) => {
         state.dataErrorWorkNuska = action.payload;
         sessionStorage.setItem('dataErrorWorkNuska', JSON.stringify(state.dataErrorWorkNuska))
      },
      setErrorWorkDataTeacher: (state, action) => {
         state.dataErrorWorkTeacher = action.payload;
         sessionStorage.setItem('dataErrorWorkTeacher', JSON.stringify(state.dataErrorWorkTeacher))
      }
   }
})

export const { 
   setTestData, setTestData5, setTestDataNuska, setTestDataTeacher,
   setErrorWorkData, setErrorWorkData5, setErrorWorkDataNuska, setErrorWorkDataTeacher
} = testSlice.actions;

export default testSlice.reducer;
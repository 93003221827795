import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { setTestData5, setTestDataNuska } from "../../../redux/slices/testSlice";
import { setConfirm } from "../../../redux/slices/confirmSlice";

import { getPanList2, PanList } from "../../../helpers/getSelectPans";
import { BASE_URL, BASE_URL_NUSKA, selectPans } from "../../../constants";

import '../../../customStyles/dashstyle.css'
import { RootState } from "../../../redux/store";
import { TarifModal } from "../../../ui/modals/TarifModal";
import { signIn } from "../../../redux/slices/authSlice";
import { getUserTarifDate } from "../../../helpers/getUserTarifDate";
import { checkUserTarifLocal } from "../../../helpers/checkUserTarifLocal";
import { FioModal } from "../../../ui/modals/FioModal";


// let panList_2 = null;


type Params = {
   nuskaId : string
}

export const TestNuskaStart = () => {
   const [loading, setLoading] = useState<boolean>(false);
   const [error, setError] = useState<string | null>(null)

   const [pan1, setPan1] = useState<number>(0);
   const [pan2, setPan2] = useState<number>(0);
   const [panList2, setPanList2] = useState<PanList[] | null>(null)

   const { nuskaId } = useParams<Params>();

   const authData = useSelector((state : RootState) => state.auth.data);
   const nuskaNumber = useSelector((state : RootState) => state.nuska.data?.nuskas)?.find(x => x.id == parseInt(nuskaId))?.number;

   const dispatch = useDispatch();
   const history = useHistory()

   const changePanHandler1 = (pan1_id : number) => {
      setPan1(pan1_id);
      setPanList2(getPanList2(pan1_id))
   }
   const changePanHandler2 = (pan2_value : number) => {
      setPan2(pan2_value);
      const btn = document.getElementById("gobuttondiv");
      if (pan2_value === 0)
         btn?.setAttribute('style', 'display: none');
      else
         btn?.setAttribute('style', 'display: block');
   }
   

   // const checkUserTarif = async () : Promise<boolean> => {
   //    if(checkUserTarifLocal(authData)) {
   //       return true;
   //    }
   //    else {
   //       console.log('fetch tarif');
      
   //       await fetch(`${BASE_URL}/api/auth/state`, {
   //          method: 'POST',
   //          headers: {
   //             'Accept' : 'application/json',
   //             'Content-Type' : 'application/json'
   //          },
   //          body: JSON.stringify({
   //             "UserId": authData?.userId
   //          })
   //       })
   //       .then(response => {
   //          if(response.ok) {
   //             return response.json()
   //          }
   //          throw new Error('*** Error checkUserTarif() Response is not OK ***')
   //       })
   //       .then(result => {
   //          if (result === null || (typeof result === 'string')) {
   //             throw new Error('*** Error checkUserTarif() Result is null or not object ***')
   //          }
   //          if (result && result.tarif >= 2 && result.tarif <= 5) {
   //             dispatch(signIn(result));
   //             if(result.testMonth === null) return false;
   
   //             const now = new Date();
   //             // console.log(new Date(result.testMonth).valueOf() - now.valueOf())
   
   //             if(new Date(result.testMonth).valueOf() - now.valueOf() > 0) {
   //                return true;
   //             }
   //             return false;
   //          }
   //          return false;
   //       })
   //       .catch(e => {
   //          console.log(e);
   //       });
   //       return false;
   //    }
   // }

   const openFioModal = () => {
      document.getElementById('open_fio_modal')?.click()
      console.log('asdasd')
   }

   const startHandler = async () => {
      setLoading(true);

      await fetch(BASE_URL_NUSKA + '/quiz/post', {
         method: 'POST',
         headers: {
            'Accept': 'application/json', 'Content-Type': 'application/json'
         },
         body: JSON.stringify({
            "UserId": authData?.userId, // "25127169-8920-4a6b-9699-25e66a180b55",
            "NuskaId": nuskaId,
            "Pan1": pan1,
            "Pan2": pan2
         })
      })
      .then(response => {
         if(response.ok) return response.json();
         throw new Error('*** Error startHandler() Response not OK ***');
      })
      .then(result => {
         setLoading(false);

         console.log(result)

         if(!result || (typeof result === 'string')){
            console.log(result);
            throw new Error('*** Error startHandler() Result is null or string ***')
         }
         else {
            if(dispatch(setTestDataNuska(result))){
               sessionStorage.removeItem('storeTime') // для корректной работы таймера
               dispatch(setConfirm(true));
               
               history.push(`/Auth/TestingNuska/${result.testId}`);
               
               window.location.reload(); // для корректной работы таймера
            }
         }
      })
      .catch((e) => {
         setLoading(false);
         // alert('Қателік кетті!')
         console.log(e);
         setError(`${e}`)
      });
   }

   return(
      <main className="ttr-wrapper">
         <TarifModal />
         <FioModal startHandler={startHandler}/>
         <div className="container-fluid">
            <div className="db-breadcrumb" style={{display: 'flex', marginBottom: '30px'}}>
               <h4 className="breadcrumb-title">{nuskaNumber + '-нұсқа'}</h4>
            </div>
            {authData && authData.tarif >= 2 && <p>Тариф бітетін уақыт: {getUserTarifDate(authData)}</p> }

            <div style={{textAlign: 'center'}}>
               <p className="pan widget-bg1">Қазақстан тарихы</p>
               <p className="pan widget-bg1">Оқу Сауаттылығы</p>
               <p className="pan widget-bg1">Математикалық Сауаттылық</p>
               <hr />
               <div className="form-group">
                  <select id="pan1" className="form-control sele" onChange={(e) => changePanHandler1(parseInt(e.target.value))}>
                     <option value={0}>Пән 1</option>
                     {selectPans.map(item => {
                        if(item.id !== 4 && item.id !== 6 && item.id !== 7 && item.id !== 19){
                           return (
                              <option key={(item.id).toString()} value={item.id}>{item.panName}</option>
                           )
                        }
                     })}
                  </select>
               </div>
               {/* <br /> */}
               <div className="form-group">
                  <select id="pan2" className="form-control sele" onChange={(e) => changePanHandler2(parseInt(e.target.value))}>
                     <option value={0}>Пән 2</option>
                     {panList2 && panList2.map(item => (
                        <option key={item.panName} value={item.id}>
                           {item.panName}
                        </option>
                     ))}
                  </select>
               </div>
               <br />
               <div className="form-group" style={{display: 'none'}} id="gobuttondiv">
                  <button onClick={() => openFioModal()} id="gobutton" className="btn widget-bg1" style={{backgroundColor: '#4c1864', color: 'white'}} disabled={loading}>
                     {loading ? 'Күте тұрыңыз...' : 'Бастау'}
                  </button>
                  {loading && <i className='ml-2 fas fa-spinner fa-pulse'></i>}
               </div>
               {error && <div style={{color: 'red'}}>{error}</div>}
            </div>
         </div>
      </main>
   )
}
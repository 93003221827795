import { ChangeEvent } from "react";

// сұрақ ауыстыру
export const changeQues = (value: number) => {
   // console.log(value);
   for(let i = 0; i < document.getElementsByClassName("quesWrap").length; i++){
      document.getElementsByClassName("quesWrap")[i].setAttribute('style', 'display: none');
   }
   document.getElementById("select" + value)?.setAttribute('style', 'display: block');
}

// сұрақ номерлерінің кестесіне арналған
export const getTd = (value: number) => {
   // console.log(value);
   for(let i = 0; i < document.getElementsByClassName("quesWrap").length; i++){
      document.getElementsByClassName("quesWrap")[i].setAttribute('style', 'display: none');
      document.getElementsByClassName('test-td-hover')[i].setAttribute('style', 'background-color: none');
   }
   document.getElementsByClassName('test-td-hover')[value - 1].setAttribute('style', 'background-color: aquamarine')
   document.getElementById("select" + value)?.setAttribute('style', 'display: block');
}

// сұрақ номерлерінің кестесіне арналған / 5 пәндік тесттердің
export const getTd5 = (panId : number, value : string) => {
   // console.log(panId);
   for(let i = 0; i < document.getElementsByClassName("quesWrap").length; i++){
      document.getElementsByClassName("quesWrap")[i].setAttribute('style', 'display: none');
      document.getElementsByClassName('test-td-hover')[i].setAttribute('style', 'background-color: none');
   }
   document.getElementById("select_" + panId + value)?.setAttribute('style', 'display: block');
   document.getElementsByClassName('test-td-hover')[parseInt(value) - 1].setAttribute('style', 'background-color: aquamarine')
}



// пән ауыстыру
export const changePanHandler = (panId : number) => {
   // console.log(panId);
   for(let i = 0; i < document.getElementsByClassName("test-table").length; i++){
      document.getElementsByClassName("test-table")[i].setAttribute('style', 'display: none');
   }
   for(let i = 0; i < document.getElementsByClassName("test-pole").length; i++){
      document.getElementsByClassName("test-pole")[i].setAttribute('style', 'display: none');
   }
   document.getElementById('data_' + panId)?.setAttribute('style', 'display: block');
   document.getElementById('select_' + panId + 1)?.setAttribute('style', 'display: block');
   document.getElementById('dataTable_' + panId)?.setAttribute('style', 'display: flex');
}



// 3 жауаптық сұрақтардың жауабын белгілеуге лимит қою
export const limitHandler = (e : ChangeEvent<HTMLInputElement>) => {
   const limit = 3;
   const currentInput : any = document.getElementById(e.target.id);
   const allInput = document.getElementsByName(e.target.name);

   let sum = 0;
   Array.from(allInput).map(item => (item as HTMLInputElement).checked && sum++);

   if(sum > limit){
      currentInput.checked = false;
      return false;
   }
   return true;
}


// тест сұрақтарындағы абзац үшін
export const getReplacedQuesStr = (str : string) : string => {
   if(!str.includes('**')) return str;
   return getReplacedQuesStr(str.replace('**', '<br/>'));
}



// var allOptions = $("ul.list-unstyled").children('li:not(.init)');


export const lfunc = (e : React.MouseEvent<HTMLLIElement>, otherId : number) => {
   let allOptions = document.querySelectorAll("ul.list-unstyled-" + otherId + " li:not(.init)");

   const currentLi = e.currentTarget;
   const currentInit : any = document.querySelector("ul.list-unstyled-" + otherId + " > .init > span");
   
   if(!currentLi.classList.contains("selected")) {
      for(let i = 0; i < allOptions.length; i++) {
         allOptions[i].classList.remove('selected')
      }
      currentLi?.classList.add("selected") // $(this).addClass('selected');
      currentInit.innerText = currentLi.innerHTML;
      
      for(let i = 0; i < allOptions.length; i++) {
         allOptions[i].setAttribute("style", "display: none;")
      }
   }
}

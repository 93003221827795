import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import { importAuthScripts } from '../../helpers/importScript';
import { BASE_URL, REQUEST_STATUSES } from '../../constants'
import { setSignDate, signIn } from '../../redux/slices/authSlice';
import { AppDispatch } from '../../redux/store';


export const Login = () => {
   const [email, setEmail] = useState<string>('');
   const [password, setPassword] = useState<string>('');

   const [error, setError] = useState<string | null>(null);
   const [loading, setLoading] = useState<boolean>(false);

   const dispatch = useDispatch<AppDispatch>();

   useEffect(() => {
      importAuthScripts();
   }, []);

   const signInHandler = async () => {
      setLoading(true);
      setError(null);
      
      if(email.trim().length < 4 || !email.trim().includes('@')) {
         setLoading(false);
         return setError('Email дұрыстап жазыңыз!');
      }
      if(password.trim().length < 4) {
         setLoading(false);
         return setError('Құпиясөзді дұрыстап жазыңыз!');
      }

      await fetch(`${BASE_URL}/api/auth/login`, {
         method: 'POST',
         headers: {
            'Accept': 'application/json', 'Content-Type': 'application/json'
         },
         body: JSON.stringify({ 
            "Login" : email, "Password" : password 
         })
      })
      .then(response => {
         if(response.ok) {
            return response.json();
         }
         throw new Error('*** Error signInHandler() Response not ok ***');
      })
      .then(result => {
         setLoading(false);

         // console.log(result); // console

         if(!result || typeof result === 'string'){
            console.log(result); // console
            throw new Error('*** Error signInHandler() Result is null or string ***');
         }
            // window.location.reload();
         
         if (result.requestStatus !== "103") {
            console.log(result); // console
            const err = REQUEST_STATUSES.find(x => x.key === parseInt(result.requestStatus));
            setError(err ? err.value : 'Қателік кетті');
         }
         
         
         
         dispatch(signIn(result));
         dispatch(setSignDate(new Date()))
      })
      .catch((error) => {
         // alert('Қателік кетті!');
         setError('Қателік кетті!');
         console.log(error);
         setLoading(false);
      })
   }

   return(
      <div className="account-form-inner">
         <div className="account-container">
               <div className="heading-bx left">
                  <h2 className="title-head">Платформаға кіру <span></span></h2>
                  <p>Егер тіркелмеген болсаңыз <Link to="/register">Тіркеліңіз</Link></p>
                  {/* <p>Егер тіркелмеген болсаңыз <a href="/register">Тіркеліңіз</a></p> */}
               </div>
               <form className="contact-bx" asp-action="Login">
                  <div className="row placeani">
                     <div className="col-lg-12">
                           <div className="form-group">
                              <div className="input-group">
                                 <label>Email (эл. почта)</label>
                                 <input
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    type="email"
                                    className="form-control"
                                    required
                                 />
                              </div>
                           </div>
                     </div>
                     <input name="ReturnUrl" style={{display: 'none'}} />
                     <div className="col-lg-12">
                           <div className="form-group">
                              <div className="input-group">
                                 <label>Құпиясөз(пароль)</label>
                                 <input
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    type="password" 
                                    className="form-control"
                                    required
                                 />
                              </div>
                           </div>
                     </div>
                     <div className="col-lg-12">
                        <div className="form-group form-forget">
                           <div className="custom-control custom-checkbox">
                              <input name="RememberMe" type="checkbox" className="custom-control-input" id="customControlAutosizing" />
                              <label className="custom-control-label" htmlFor="customControlAutosizing">Сақтау</label>
                           </div>
                           <a href="~/Auth/Reset" className="ml-auto">Пароль ұмытсаңыз?</a>
                        </div>
                     </div>

                     <div className="col-lg-12 m-b30">
                        <button type="button" onClick={() => signInHandler()} disabled={loading} className="btn button-md">
                           {loading ? (
                              <span>Күте тұрыңыз </span>
                           ) : 'Кіру'}
                        </button>
                        {loading && <i className='ml-2 fas fa-spinner fa-pulse'></i>}
                        {error && <p style={{color: 'red'}}>{error}</p>}
                     </div>
                  </div>
               </form>
         </div>
      </div>
   )
}
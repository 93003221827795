import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { BASE_URL } from "../../../constants";
import { setErrorWorkData } from "../../../redux/slices/testSlice";
import { AppDispatch, RootState } from "../../../redux/store";
import { getStrDate } from "../../../helpers/getStrDate";
import { TestHistorySkeleton } from "./TestHistorySkeleton";


export const OneTestHistory = () => {
   const [loading, setLoading] = useState<number | null>(null);

   const authUserId = useSelector((state : RootState) => state.auth.data?.userId);
   const data = useSelector((state : RootState) => state.testHistory.data);

   const history = useHistory();
   const dispatch = useDispatch<AppDispatch>();

   const getTestHistory = async (testId : number, ball : number) => {
      setLoading(testId);
      
      try{
         await fetch(`${BASE_URL}/api/test/onemistake`, {
            method: 'POST',
            headers: {
               'Accept': 'application/json',
               'Content-Type': 'application/json'
            },
            body: JSON.stringify({
               "Id": testId,
               "UserId": authUserId
            })
         })
         .then(response => {
            if(response.ok){
               return response.json();
            }
            throw new Error('*** Error getTestHistory() Response not OK ***')
         })
         .then(result => {
            setLoading(null);

            if(!result || (typeof result === 'string')) {
               console.log(result); 
               throw new Error('*** Error getTestHistory() Result null or string ***')
            }
            else {
               dispatch(setErrorWorkData({
                  id: testId,
                  ball,
                  items: result
               }));
      
               history.push(`/Auth/TestWork/${testId}`);
            }
         })
      }
      catch(e) {
         // alert('Қателік кетті!');
         console.log(e); 
      }
   }

   return (
      <div className="col-md-7">
         <div className="widget-box">
            <div className="wc-title">
               <h4>1 пәндік тапсырылған тесттер:</h4>
            </div>
            <div className="widget-inner row" id="pan">
               <div className="col-lg-12 m-b30">
                  <div className="widget-box">
                     <div className="widget-inner">
                        <div className="noti-box-list">
                           <ul>
                              {data ? (data.oneItems && data.oneItems.length !== 0 ? data.oneItems.map((item, i) => (
                                 <li key={i.toString()}>
                                    <span className="notification-icon dashbg-green">
                                       <i className="fa fa-check"></i>
                                    </span>
                                    <span className="notification-text date-ball">
                                       <a>{item.panName + ' | '}</a>
                                       <span>
                                       {item.ball} балл | {getStrDate(item.createDate)}
                                       </span>
                                    </span>
                                    <button onClick={() => getTestHistory(item.id, item.ball)} className="notification-time btn" disabled={loading === item.id}>
                                       {loading === item.id
                                          ? <i className='fas fa-spinner fa-pulse'></i>
                                          : <i className="fa fa-angle-double-right"></i>
                                       }
                                    </button>
                                 </li>
                              )) : (
                                 <>
                                    <span>Пән бойынша тест тапсырылмаған!</span>
                                    <br/>
                                    <Link to="/Auth/Test1" className="btn gold mt-2">
                                       Тапсыру
                                    </Link>
                                 </>
                              )
                              ) : new Array(1, 2, 3, 4, 5).map(x => (
                                 <TestHistorySkeleton key={'sk_1_' + x.toString()} />
                              ))}
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { BASE_URL_ANALIZ_IMG, BASE_URL_IMG, BASE_URL_NUSKA_IMG, selectPans } from "../../../constants";
import { RootState } from "../../../redux/store";
import { changePanHandler, getReplacedQuesStr, getTd5 } from "../../../helpers/testScript";

export const TestWorkNuska = () => {
   const [activePan, setActivePan] = useState<number | null>(null);

   const testData = useSelector((state : RootState) => state.test.dataErrorWorkNuska);

   const history = useHistory();

   useEffect(() => {
      // console.log(testData)
      testData && setActivePan(testData.items[0].panId);

      if (!sessionStorage.getItem('dataErrorWorkNuska')) {
         return history.push('/')
      }
      sessionStorage.removeItem('storeTime');
      // return () => window.location.reload();
   }, [])

   const getInputBgColor = (panId : number, forId : number, value : string) => {
      const color1 = '#4cbd79'; // дұрыс жауап
      const color2 = '#ff2b35'; // Белгіленген қате жауап
      const color3 = '#cdbb18'; // Белгіленген дұрыс жауап
      const defaultColor = '#333';


      const testItems = testData.items.find(x => x.panId === panId)?.items;

      const succes = testItems?.find(x => x.forId === forId)?.succes;
      const otvet = testItems?.find(x => x.forId === forId)?.otvet;

      if(otvet && succes) {
         if(otvet.includes(value)){
            if(succes.includes(value))
               return color3;
            else
               return color2;
         }
         else {
            return defaultColor;
         }
      }
      if(!otvet || !succes) {
         return defaultColor; 
      }
   }

   return (
      <main className="ttr-wrapper">
         <div className="container-fluid">
            <div className="db-breadcrumb err-breadcrumb">
               <div style={{display: 'flex', alignItems: 'center'}}>
                  <h4 className="breadcrumb-title">Пән:</h4>

                  <select className="form-control" onChange={(e) => {
                     setActivePan(parseInt(e.target.value))
                     changePanHandler(parseInt(e.target.value))
                  }}>
                     {testData && testData.items.map((item, index) => {
                        const panName = selectPans.find(x => x.id === item.panId)?.panName;
                        return (
                           <option key={panName} value={item.panId}>
                              {panName}
                           </option>
                        )
                     })}
                  </select>
               </div>
               <h5 style={{margin: 0}}>Қатемен жұмыс</h5>
            </div>
            {testData && (
               <div className="total_analiz_wrap">
                  <div>
                     <h4 className="ball-h4">
                        <span>Жинаған балыңыз: </span>
                        <span style={{color: 'mediumseagreen'}}>
                           {testData.ball} - балл
                        </span>
                     </h4>

                     {testData.items.map(item => {
                        const panName = selectPans.find(x => x.id === item.panId)?.panName;
                        return (
                           <h6 key={item.panId + 'ball' + item.ball}>
                              <span>{panName}: </span>
                              <span style={{color: 'mediumseagreen'}}>{item.ball}</span>
                           </h6>
                        )
                     })}
                  </div>
                  <div className="analiz_wrap">
                     <h4>Анализ нәтижесі</h4>
                     <div className="analiz">
                        {testData.items.find(x => x.panId === activePan)?.items.map(item => (
                           item.questionTopik && item.otvet !== item.succes && 
                              <span key={'questopik_'+item.forId}>
                                 {item.forId + '. ' + item.questionTopik}
                                 <br/>
                              </span>
                        ))}
                     </div>
                  </div>
               </div>
            )}
            <div style={{overflowX: 'auto'}}>
               {testData && testData.items.map((data, i) => (
                  <table key={'dataTable_' + data.panId} id={'dataTable_' + data.panId} className="test-table" style={{display: i !== 0 ? 'none' : 'flex'}}>
                     <tbody><tr>
                        {data.items.map((item, index) => (
                           <td key={item.id + (index + 1).toString()} onClick={(e) => getTd5(data.panId, e.currentTarget.innerText)} className="test-td-hover">
                              {item.forId}
                           </td>
                        ))}
                     </tr><tr>
                        {data.items.map(item => {
                           const arr = item.otvet ? item.otvet.split("") : [];

                           return (
                              <td key={'otv' + item.id} id={'otv' + item.id}>
                                 {arr.length !== 0 ? arr.map((x, i) => {
                                    return item.forId > 35 ? (
                                       <span key={data.panId + 'arr' + item.other_35_40[i].id} style={{color: item.other_35_40[i].otvet === item.other_35_40[i].succes ? '#cdbb18' : '#ff2b35'}}>{x}</span>
                                    ) : (
                                       <span key={data.panId + 'arr' + x + item.forId} style={{color: getInputBgColor(data.panId, item.forId, x)}}>{x}</span>
                                    )
                                 }) : ""}
                                 <span>({item.itemBall})</span>
                              </td>
                           )
                        })}
                     </tr></tbody>
                  </table>
               ))}
            </div>

            <br />

            {testData && testData.items.map((data, i) => (
               <div id={'data_' + data.panId} key={'data_' + data.panId} className="test-pole" style={{display: i !== 0 ? 'none' : 'block'}}>
                  {data.items.map((item, index) => {
                     const type = (index + 1) > 30 ? "checkbox" : "radio";
                     // console.log(item);

                     let inputArr = [
                        { key: 'A', value: item.a },
                        { key: 'B', value: item.b },
                        { key: 'C', value: item.c },
                        { key: 'D', value: item.d },
                        // { key: 'E', value: item.e }
                     ]
                     type === 'checkbox' && inputArr.push(
                        { key: 'E', value: item.e },
                        { key: 'F', value: item.f ? item.f : '' },
                        // { key: 'G', value: item.g ? item.g : '' },
                        // { key: 'H', value: item.h ? item.h : '' }
                     );

                     return (
                        <div className="quesWrap" key={'quesWrap_' + data.panId + (index+1)} id={'select_' + data.panId + (index + 1)} style={{display: index === 0 ? 'block' : 'none'}}>
                           <a className="test-number" id="test-number">{index + 1} - сұрақ</a>
                           {item.questionTopik && (
                              <a className="test-number analiz_title" style={{background: '#eef'}}>{item.questionTopik}</a>
                           )}
                           {item.topUrl && 
                              <>
                                 <br/>
                                 <img src={BASE_URL_NUSKA_IMG + item.topUrl} style={{minHeight: '25vh', maxHeight: '50vh', width: 'auto', maxWidth: '100%', objectFit: 'contain'}} alt="" />
                              </>
                           }
                           {item.name && <p className="test-text" dangerouslySetInnerHTML={{__html: getReplacedQuesStr(item.name)}}></p>}
                           {item.buttomUrl && <img src={BASE_URL_NUSKA_IMG + item.buttomUrl} style={{minHeight: '25vh', maxHeight: '50vh', width: 'auto', maxWidth: '100%'}} alt=""/>}
                           {item.name2 && <p className="test-text" dangerouslySetInnerHTML={{__html: getReplacedQuesStr(item.name2)}}></p>}
         

                           {(index + 1) < 36 && inputArr.map((x, i) => {
                              const color = getInputBgColor(data.panId, item.forId, x.key)
                              return (
                                 <div className="form_radion_btn" key={('radid_' + data.panId + (index + 1)) + (i+1)}>
                                    <input type={type} id={('radid_' + data.panId + (index + 1)) + (i+1)} value={i+1} name={data.panId + '_' + item.forId} disabled/>
                                    <label htmlFor={('radid_' + data.panId + (index + 1)) + (i+1)} style={{color, borderColor: color}}>
                                       <span style={{paddingRight: '10px'}}>{x.key} |</span>
                                       {item.panId === 17 ? <span>{x.value}</span> : <span dangerouslySetInnerHTML={{__html: x.value}}></span>}
                                    </label>
                                    <br/>
                                 </div>
                              )
                           })}

                           {item.other_35_40 && item.other_35_40.length > 0 && item.other_35_40.map((x, i) => {
                              let optionArr = [
                                 { key: 'A', value: x.a },
                                 { key: 'B', value: x.b },
                                 { key: 'C', value: x.c },
                                 { key: 'D', value: x.d },
                              ]
                              const ov = optionArr.find(a => a.key === x.otvet);
                              const ovv : string = ov && ov.value != null ? ov.value : "Таңдау";

                              return (
                                 <div className="form-group d-flex" key={'other_' + i}>
                                    <label style={{display: 'block',maxWidth: '30%'}} dangerouslySetInnerHTML={{__html: x.name ? x.name : ''}}></label>
                                    {/* <select className="form-control sele" defaultValue={x.otvet ? x.otvet : ' '} style={{borderColor: x.otvet ? x.otvet === x.succes ? '#cdbb18' : '#ff2b35' : '#ccc'}}>
                                       <option value=" ">Белгіленбеді</option>
                                       {optionArr.map(x => (
                                          <option key={x.key+i} value={x.key} dangerouslySetInnerHTML={{__html: x.key + ') ' + x.value}}></option>
                                       ))}
                                    </select> */}
                                    
                                    <ul className={i === 0 ? "list-unstyled list-unstyled-0 " + "list-unstyled-" + x.id : "list-unstyled " + "list-unstyled-" + x.id}>
                                       <li className="init d-flex justify-content-between align-items-center" style={{borderColor: x.otvet ? x.otvet === x.succes ? '#cdbb18' : '#ff2b35' : '#ccc'}}>
                                          <span dangerouslySetInnerHTML={{__html:  ovv}}></span>
                                          <i className="fa fa-chevron-down"></i>
                                       </li>
                                       
                                       {optionArr.map(a => (
                                          <li 
                                             className={x.otvet === a.key ? "selected" : ""} 
                                             key={a.key+i}
                                             data-value={a.key}
                                             dangerouslySetInnerHTML={{__html: a.key + ') ' + a.value}}
                                          ></li>
                                       ))}
                                    </ul>

                                 </div>
                              )
                           })}

                        </div>
                     )
                  })}
               </div>
            ))}
         </div>
      </main>
   )
}



export interface PanList {
   id: number
   panName: string
}

const selectedPanItems = [
   {key: 1, pans: <PanList[]>[
      {id: 8,  panName: 'Математика'},
      {id: 2,  panName: 'Биология'},
      {id: 15, panName: 'Ағылшын'},
      {id: 10, panName: 'Дүниежүзі тарихы'}
   ]},
   {key: 2, pans: <PanList[]>[
      {id: 1,  panName: 'География'}, {id: 3,  panName: 'Химия'}
   ]},
   {key: 3, pans: <PanList[]>[
      {id: 2,  panName: 'Биология'}, {id: 9,  panName: 'Физика'}
   ]},
   {key: 8, pans: <PanList[]>[
      {id: 1,  panName: 'География'}, {id: 9,  panName: 'Физика'}, {id: 17,  panName: 'Информатика'}
   ]},
   {key: 9, pans: <PanList[]>[
      {id: 3,  panName: 'Химия'}, {id: 8,  panName: 'Математика'}
   ]},
   {key: 10, pans: <PanList[]>[
      {id: 1,  panName: 'География'},
      {id: 15, panName: 'Ағылшын'},
      {id: 11, panName: 'Құқық негіздері'}
   ]},
   {key: 11, pans: <PanList[]>[{id: 10, panName: 'Дүниежүзі тарихы'}]},
   {key: 12, pans: <PanList[]>[{id: 13, panName: 'Қазақ әдебиеті'}]},
   {key: 13, pans: <PanList[]>[{id: 12, panName: 'Қазақ тілі'}]},
   {key: 15, pans: <PanList[]>[
      {id: 1,  panName: 'География'}, {id: 10, panName: 'Дүниежүзі тарихы'}
   ]},
   {key: 17, pans: <PanList[]>[
      {id: 8,  panName: 'Математика'}
   ]}
]

export const getPanList2 = (pan1 : number) : PanList[] | null => {
   document.getElementById("gobuttondiv")?.setAttribute('style', 'display: none');
   
   const panList2 = selectedPanItems.find(x => x.key === pan1)?.pans;

   return panList2 ? panList2 : null;
}
import React from "react";

export const Tarif = () => {

   const links = {
      one:   'https://wa.me/77473987068?text=%D0%A1%D3%99%D0%BB%D0%B5%D0%BC%D0%B5%D1%82%D1%81%D1%96%D0%B7%20%D0%B1%D0%B5!%0A%D0%9C%D0%B5%D0%BD%20Geoid%20%D0%BF%D0%BB%D0%B0%D1%82%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%81%D1%8B%D0%BD%D0%B4%D0%B0%20%D1%82%D0%B5%D1%81%D1%82%20%D1%82%D0%B0%D0%BF%D1%81%D1%8B%D1%80%D1%83%D2%93%D0%B0%20%D0%B0%D1%80%D0%BD%D0%B0%D0%BB%D2%93%D0%B0%D0%BD%20%221%20%D0%B0%D0%B9%22%20%D0%BF%D0%B0%D0%BA%D0%B5%D1%82%D1%82%D1%96%20%D1%81%D0%B0%D1%82%D1%8B%D0%BF%20%D0%B0%D0%BB%D2%93%D1%8B%D0%BC%20%D0%BA%D0%B5%D0%BB%D0%B5%D0%B4%D1%96',
      three: 'https://wa.me/77473987068?text=%D0%A1%D3%99%D0%BB%D0%B5%D0%BC%D0%B5%D1%82%D1%81%D1%96%D0%B7%20%D0%B1%D0%B5!%0A%D0%9C%D0%B5%D0%BD%20Geoid%20%D0%BF%D0%BB%D0%B0%D1%82%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%81%D1%8B%D0%BD%D0%B4%D0%B0%20%D1%82%D0%B5%D1%81%D1%82%20%D1%82%D0%B0%D0%BF%D1%81%D1%8B%D1%80%D1%83%D2%93%D0%B0%20%D0%B0%D1%80%D0%BD%D0%B0%D0%BB%D2%93%D0%B0%D0%BD%20%223%20%D0%B0%D0%B9%22%20%D0%BF%D0%B0%D0%BA%D0%B5%D1%82%D1%82%D1%96%20%D1%81%D0%B0%D1%82%D1%8B%D0%BF%20%D0%B0%D0%BB%D2%93%D1%8B%D0%BC%20%D0%BA%D0%B5%D0%BB%D0%B5%D0%B4%D1%96',
      six:   'https://wa.me/77473987068?text=%D0%A1%D3%99%D0%BB%D0%B5%D0%BC%D0%B5%D1%82%D1%81%D1%96%D0%B7%20%D0%B1%D0%B5!%0A%D0%9C%D0%B5%D0%BD%20Geoid%20%D0%BF%D0%BB%D0%B0%D1%82%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%81%D1%8B%D0%BD%D0%B4%D0%B0%20%D1%82%D0%B5%D1%81%D1%82%20%D1%82%D0%B0%D0%BF%D1%81%D1%8B%D1%80%D1%83%D2%93%D0%B0%20%D0%B0%D1%80%D0%BD%D0%B0%D0%BB%D2%93%D0%B0%D0%BD%20%226%20%D0%B0%D0%B9%22%20%D0%BF%D0%B0%D0%BA%D0%B5%D1%82%D1%82%D1%96%20%D1%81%D0%B0%D1%82%D1%8B%D0%BF%20%D0%B0%D0%BB%D2%93%D1%8B%D0%BC%20%D0%BA%D0%B5%D0%BB%D0%B5%D0%B4%D1%96'
   }

   return (
      <div className="content-block ttr-wrapper">
         <div className="section-area ">
            <div className="container-fluid">
                  <div className="row">
                     <div className="col-md-12 heading-bx text-center">
                        <h2 className="title-head text-uppercase m-b0 member-ewe">Geoid_Edu Платформасының оқу материалдарына арналған пакеттер бөлімі!<br /></h2>
                     </div>
                  </div>
                  <div className="pricingtable-row">
                     <div className="row">
                        <div className="col-sm-12 col-md-4 col-lg-4 m-b40">
                           <div className="pricingtable-wrapper">
                                 <div className="pricingtable-inner">
                                    <div className="pricingtable-main">
                                       <div className="pricingtable-price">
                                             <span className="priceing-doller">тг</span>
                                             <span className="pricingtable-bx">5,000</span>
                                             {/* <span className="pricingtable-type">1 ай</span> */}
                                       </div>
                                       <div className="pricingtable-title">
                                             {/* <h2>«Standard»</h2> */}
                                             <h2>«1 ай»</h2>
                                             <p>Пакет</p>
                                       </div>
                                    </div>
                                    <ul className="pricingtable-features">
                                       <li>Күніне 1 рет толық ҰБТ</li>
                                       <li>Күніне 3 рет жеке пәндік тест</li>
                                       {/* <li>Тегін материалдар базасы</li>
                                       <li>«Сұрақ-жауап» іздеу функциясы </li> */}
                                    </ul>
                                    <div className="pricingtable-footer">
                                       <a href={links.one} target="_blank" className="btn radius-xl">Пакетті алу</a>
                                    </div>
                                 </div>
                           </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 m-b40">
                           <div className="pricingtable-wrapper">
                                 <div className="pricingtable-inner pricingtable-highlight">
                                    <div className="pricingtable-main">
                                       <div className="pricingtable-price">
                                             <span className="priceing-doller">тг</span>
                                             <span className="pricingtable-bx">10,000</span>
                                             {/* <span className="pricingtable-type">3 ай</span> */}
                                       </div>
                                       <div className="pricingtable-title">
                                             {/* <h2>«Silver»</h2> */}
                                             <h2>«3 ай»</h2>
                                             <p>Пакет</p>
                                       </div>
                                    </div>
                                    <ul className="pricingtable-features">
                                       <li>Күніне 1 рет толық ҰБТ</li>
                                       <li>Күніне 3 рет жеке пәндік тест</li>
                                       {/* <li>Тегін материалдар базасы</li>
                                       <li>«Сұрақ-жауап» іздеу функциясы </li>
                                       <li>Күніне 1 рет, География пәнінен жаңа форматта тегін тест тапсыру мүмкіндігі</li>
                                       <li>GEOID_EDU онлайн-курсының тақырыптық видеосабақтары</li>
                                       <li>Тест талдау видеосабақтары</li>
                                       <li>Географиялық номенклатура бойынша видеосабақтар</li> */}
                                    </ul>
                                    <div className="pricingtable-footer">
                                       <a href={links.three} target="_blank" className="btn radius-xl">Пакетті алу</a>
                                    </div>
                                 </div>
                           </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 m-b40">
                           <div className="pricingtable-wrapper">
                                 <div className="pricingtable-inner">
                                    <div className="pricingtable-main">
                                       <div className="pricingtable-price">
                                             <span className="priceing-doller">тг</span>
                                             <span className="pricingtable-bx">15,000</span>
                                             {/* <span className="pricingtable-type">6 ай</span> */}
                                       </div>
                                       <div className="pricingtable-title">
                                             {/* <h2>«Gold»</h2> */}
                                             <h2>«6 ай»</h2>
                                             <p>Пакет</p>
                                       </div>
                                    </div>
                                    <ul className="pricingtable-features">
                                       <li>Күніне 1 рет толық ҰБТ</li>
                                       <li>Күніне 3 рет жеке пәндік тест</li>
                                       {/* <li>Тегін материалдар базасы</li>
                                       <li>«Сұрақ-жауап» іздеу функциясы </li>
                                       <li>Күніне 1 рет, География пәнінен жаңа форматта тегін тест тапсыру мүмкіндігі</li>
                                       <li>GEOID_EDU онлайн-курсының тақырыптық видеосабақтары</li>
                                       <li>Тест талдау видеосабақтары</li>
                                       <li>Географиялық номенклатура бойынша видеосабақтар</li>
                                       <li>Куратормен жұмыс</li>
                                       <li>Аптасына 1 рет kahoot ойыны ұйымдастырылады</li>
                                       <li>Аптасына 1 рет Өмірзақ Нұрдәулет ағаймен тікелей эфирде сабақ өту</li> */}
                                    </ul>
                                    <div className="pricingtable-footer">
                                       <a href={links.six} target="_blank" className="btn radius-xl">Пакетті алу</a>
                                    </div>
                                 </div>
                           </div>
                        </div>
                     </div>
                  </div>
            </div>
         </div>
      </div>
   )
}
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BASE_URL } from '../../../constants'
import { setTestHistoryData } from '../../../redux/slices/testHistorySlice';
import { RootState } from '../../../redux/store';
import { FiveTestHistory } from './FiveTestHistory';
import { OfficialTest } from './OfficialTest';
import { OneTestHistory } from './OneTestHistory';
import { PersonalDetails } from './PersonalDetails';


export const Account = () => {
   const authUserId = useSelector((state : RootState) => state.auth.data?.userId)
   const testHistoryData = useSelector((state : RootState) => state.testHistory.data);

   // const history = useHistory();
   const dispatch = useDispatch();

   const loadHistoryItems = async () => {
      await fetch(`${BASE_URL}/api/profile`, {
         method: 'POST',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
         },
         body: JSON.stringify({
            "UserId": authUserId
         })
      })
      .then(response => {
         if(response.ok)
            return response.json();
         throw new Error('*** Error loadHistoryItems() Response not ok ***');
      })
      .then(result => {
         // console.log(result);
         if(!result) {
            console.log(result);
            throw new Error('*** Error loadHistoryItems() Result is null ***')
         }
         dispatch(setTestHistoryData(result))
      })
      .catch(error => {
         // alert('Қателік кетті');
         console.log(error);
      })

   }

   useEffect(() => {
      !testHistoryData && loadHistoryItems()
   }, [])


   return (
      <main className="ttr-wrapper">
         <div className="container-fluid">
            <div className="db-breadcrumb">
               <h4 className="breadcrumb-title">Жеке кабинет</h4>
            </div>	
               <div className="row">
                  <div className="col-lg-12 m-b30">
                     <div className="row">
                        <PersonalDetails />
                        <OfficialTest />
                     </div>
                  </div>

                  <div className="col-lg-12 m-b30">
                     <div className="row">
                        <OneTestHistory />
                        <FiveTestHistory />
                     </div>
                  </div>
               </div>
         </div>
      </main>
   );
};






import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";

import { importContentScripts } from "../helpers/importScript";

import { Header } from "../components/content/Header";
import { SideBar } from "../components/content/SideBar";
import { Account } from "../components/content/account/Account";
import { Lessons } from "../components/content/Lessons";

import { Test1 } from "../components/content/test_1/Test1";
import { Testing1 } from "../components/content/test_1/Testing1";
import { TestErrorWork } from "../components/content/test_1/TestErrorWork";

import { Test5 } from "../components/content/test_5/Test5";
import { Testing5 } from "../components/content/test_5/Testing5";

import { VideoGroup } from "../components/content/video/VideoGroup";
// import { VideoTestGroup } from "../components/content/video/VideoTestGroup";
// import { VideoGeoGroup } from "../components/content/video/VideoGeoGroup";
import { VideoShow } from "../components/content/video/VideoShow";
import { TestErrorWork5 } from "../components/content/test_5/TestErrorWork5";
import { Search } from "../components/content/Search";
import { RootState } from "../redux/store";
import { TestOffice } from "../components/content/test_office/TestOffice";
import { TestingOffice } from "../components/content/test_office/TestingOffice";
import { TestErrorWorkOffice } from "../components/content/test_office/TestErrorWorkOffice";
import { Tarif } from "../components/content/Tarif";
import { TestTeacherStart } from "../components/content/test_teacher/TestTeacherStart";
import { TestingTeacher } from "../components/content/test_teacher/TestingTeacher";
import { TestErrorWorkTeacher } from "../components/content/test_teacher/TestErrorWorkTeacher";
import { TestAnaliz } from "../components/content/test_analiz/TestAnaliz";
import { TestingAnaliz } from "../components/content/test_analiz/TestingAnaliz";
import { TestErrorWorkAnaliz } from "../components/content/test_analiz/TestErrorWorkAnaliz";
import { ConnectionModal } from "../ui/modals/ConnectionModal";
import { TestNuska } from "../components/content/test_nuska/TestNuska";
import { TestNuskaStart } from "../components/content/test_nuska/TestNuskaStart";
import { TestingNuska } from "../components/content/test_nuska/TestingNuska";
import { TestWorkNuska } from "../components/content/test_nuska/TestWorkNuska";


export const Content = () => {
   const[isOnline, setIsOnline] = useState(navigator.onLine)
   let pathname = window.location.pathname;

   const authUserId = useSelector((state :RootState) => state.auth.data?.userId);

   const history = useHistory();
   const location = useLocation()

   
   !navigator.onLine
   ? document.getElementById('open_connection_modal')?.click() 
   : document.getElementById('close_connection_modal')?.click();

   useEffect(() => {
      importContentScripts();
      // return () => importContentScripts();
   }, [])
   useEffect(() => {
      history.listen((location, action) => {
         if(location.pathname !== pathname){
            document.body.className = "ttr-pinned-sidebar ttr-body-fixed ttr-opened-sidebar"
            pathname = location.pathname;
         }
         // console.log(pathname);
      })
   }, [location.pathname])

   return(
      <>
         <ConnectionModal />

         <Header />
         <SideBar /> 

         <Switch>
            <Route path="/Auth/Account" exact component={Account} />
            <Route path="/Auth/Profil" exact component={Lessons} />
            <Route path="/Auth/VideoGroup/:predmetId" exact component={VideoGroup} />
            {/* <Route path="/Auth/VideoTestGroup" exact component={VideoTestGroup} /> */}
            {/* <Route path="/Auth/VideoGeoGroup" exact component={VideoGeoGroup} /> */}
            <Route path="/Auth/VideoShow/:predmetId/:videoGroupId" exact component={VideoShow} />
            <Route path="/Auth/VideoShow/:predmetId/:videoGroupId/:videoId" exact component={VideoShow} />

            <Route path="/Auth/Test1" exact component={Test1} />
            <Route path="/Auth/Test1/:testId" exact component={Testing1} />
            <Route path="/Auth/TestWork/:testId" exact component={TestErrorWork} />

            <Route path="/Auth/Test5" exact component={Test5} />
            <Route path="/Auth/Test5/:fiveId" exact component={Testing5}/>
            <Route path="/Auth/TestWork5/:fiveId" exact component={TestErrorWork5} />

            <Route path="/Auth/OfficeTest/:fiveId" exact component={TestOffice} />
            <Route path="/Auth/OfficeTesting/:fiveId" exact component={TestingOffice} />
            <Route path="/Auth/OfficeTestWork/:fiveId" exact component={TestErrorWorkOffice} />


            <Route path="/Auth/TestTeacher" exact component={TestTeacherStart} />
            <Route path="/Auth/TestTeacher/:testId" exact component={TestingTeacher} />
            <Route path="/Auth/TestWorkTeacher/:testId" exact component={TestErrorWorkTeacher} />

            <Route path="/Auth/TestAnaliz" exact component={TestAnaliz} />
            <Route path="/Auth/TestAnaliz/:fiveId" exact component={TestingAnaliz} />
            <Route path="/Auth/TestWorkAnaliz/:fiveId" exact component={TestErrorWorkAnaliz} />

            <Route path="/Auth/TestNuska" exact component={TestNuska} />
            <Route path="/Auth/TestNuskaStart/:nuskaId" exact component={TestNuskaStart} />
            <Route path="/Auth/TestingNuska/:testId" exact component={TestingNuska} />
            <Route path="/Auth/TestWorkNuska/:testId" exact component={TestWorkNuska} />



            <Route path="/Auth/Tarif" exact component={Tarif} />
            <Route path="/Auth/Search" exact component={Search} />
            <Route path="*" component={() => <Redirect to={!authUserId ? '/' : '/Auth/Profil'}/>} />
            {/* <Route path="*" component={() => <NavLink to={!authUserId ? '/' : '/Auth/Profil'}/>} /> */}
         </Switch>
         
         <div className="ttr-overlay"></div>
      </>
   )
}
import React from "react";

export const ConfirmModal = () => {
   return (
      <> 
         <button style={{display: 'none'}} id="open_confirm" type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
            Launch demo modal
         </button>

         <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
               <div className="modal-content">
                  <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Ескерту!</h5>
                  {/* <h5 className="modal-title" id="exampleModalLabel">Тестті аяқтау!</h5> */}
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                  </button>
                  </div>
                  <div className="modal-body">
                     <p>Сіз әлі тестті аяқтамадыңыз!</p>
                     <p>Басқа бетке өту үшін тестті аяқтаңыз!</p>
                     {/* <p>Басқа бетке өтер болсаңыз, белгіленген жауаптар сақталмайды және тест автоматты түрде аяқталады!</p> */}
                  </div>
                  <div className="modal-footer">
                     <button id="close_confirm" type="button" className="btn btn-secondary" data-dismiss="modal">OK</button>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { setFioWithModal } from "../../redux/slices/authSlice";


interface Props {
   startHandler: () => void
}

export const FioModal = ({startHandler} : Props) => {
   const [fio, setFio] = useState<string>('');
   const [error, setError] = useState<string | null>(null)

   const dispatch = useDispatch<AppDispatch>()

   const checkForm = () => {
      setError(null);
      
      if(fio.trim().length < 6) {
         return setError('Аты-жөніңізді толық жазыңыз!');
      }
      dispatch(setFioWithModal(fio.trim()))

      startHandler()
   }

   return(
      <> 
         <button style={{display: 'none'}} id="open_fio_modal" type="button" className="btn btn-primary" data-toggle="modal" data-target="#fioModal">
            Launch demo modal
         </button>

         <div className="modal fade" id="fioModal" tabIndex={-1} role="dialog" aria-labelledby="fioModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
               <div className="modal-content">
                  <div className="modal-header">
                  <h5 className="modal-title" id="fioModalLabel">Аты-жөні:</h5>
                  {/* <h5 className="modal-title" id="exampleModalLabel">Тестті аяқтау!</h5> */}
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                  </button>
                  </div>
                  <div className="modal-body">
                     <div className="form-group row">
                        {/* <label className="col-sm-3 col-form-label">Аты-Жөні</label> */}
                        <div className="col-sm-9">
                           <input className="form-control" type="text" value={fio} onChange={(e) => setFio(e.target.value)} />
                           {error && <p style={{color: 'red', fontSize: 14}}>{error}</p>}
                        </div>
                     </div>
                  </div>
                  <div className="modal-footer">
                     <button type="button" className="btn btn-secondary"  onClick={() => checkForm()} disabled={fio.trim().length === 0}>OK</button>
                     <button style={{display: 'none'}} id="close_fio" type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}
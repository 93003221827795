import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { setTestData5 } from "../../../redux/slices/testSlice";
import { setConfirm } from "../../../redux/slices/confirmSlice";

import { getPanList2, PanList } from "../../../helpers/getSelectPans";
import { BASE_URL, BASE_URL_ANALIZ, BASE_URL_OFFICE, selectPans } from "../../../constants";

import '../../../customStyles/dashstyle.css'
import { RootState } from "../../../redux/store";
import { TodayModal } from "../../../ui/modals/TodayModal";


export const TestAnaliz = () => {
   const [fiveId, setFiveId] = useState<number | null>(null);

   const [loading, setLoading] = useState<boolean>(false);
   const [error, setError] = useState<string | null>(null)
   const [pan1, setPan1] = useState<number>(0);
   const [pan2, setPan2] = useState<number>(0);
   const [panList2, setPanList2] = useState<PanList[] | null>(null)

   const authUserId = useSelector((state : RootState) => state.auth.data?.userId);


   const dispatch = useDispatch();
   const history = useHistory();


   const changePanHandler1 = (pan1_id : number) => {
      setPan1(pan1_id);
      setPanList2(getPanList2(pan1_id))
   }
   const changePanHandler2 = (pan2_value : number) => {
      setPan2(pan2_value);

      if (pan2_value === 0) 
         document.getElementById("gobuttondiv")?.setAttribute('style', 'display: none');
      else 
         document.getElementById("gobuttondiv")?.setAttribute('style', 'display: block');
   }

   const startHandler = async () => {
      // console.log(fiveId)
      try {
         setLoading(true);

         await fetch(BASE_URL_ANALIZ + '/api/analiz/build', {
            method: 'POST',
            headers: {
               'Accept': 'application/json', 'Content-Type': 'application/json'
            },
            body: JSON.stringify({
               // "userId": "ade7a1c5-2da0-4362-818e-1e003256eda1",
               "userId": authUserId,
               "fiveId": fiveId,
               "pan1": pan1,
               "pan2": pan2
            })
         })
         .then(response => {
            if(response.ok) return response.json();
            throw new Error('*** Error startHandler() Response not OK ***');
         })
         .then(result => {
            setLoading(false);

            // console.log(result);

            if(!result || (typeof result === 'string')){
               // console.log(result);
               throw new Error('*** Error startHandler() Result is null or string ***')
            }
            else {
               if(dispatch(setTestData5(result))){
                  sessionStorage.removeItem('storeTime'); // для корректной работы таймера
                  dispatch(setConfirm(true));
                  history.push(`/Auth/TestAnaliz/${result.id}`);
                  
                  window.location.reload(); // для корректной работы таймера
               }
            }
         })
      }
      catch(e) {
         setLoading(false);
         // alert('Қателік кетті!')
         console.log(e);
         setError(`${e}`)
      }
   }

   const getTodayFiveId = async() => {
      await fetch(`${BASE_URL_ANALIZ}/api/analiz/today`, {
         method: 'POST',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
         },
         body: JSON.stringify({
            // "userId" : "ade7a1c5-2da0-4362-818e-1e003256eda1"
            "userId" : authUserId
         })
      })
      .then(response => {
         if(response.ok){
            return response.json()
         }
      })
      .then(result => {
         // console.log(result)
         if(result && result.fiveId != null) {
            if(result.fiveId == 0) {
               document.getElementById('open_today_modal')?.click();
               
            }
            setFiveId(result.fiveId)
         }
         else {
            setError('Қателік кетті! Қайталаңыз!');
         }
      })
      .catch(error => {
         console.log(error);
         setError('Қателік кетті! Қайталаңыз!');
      })
   }

   useEffect(() => {
      getTodayFiveId()
   }, [])
   

   return(
      <>
         <TodayModal />
         <main className="ttr-wrapper">
            <div className="container-fluid">
               <div className="db-breadcrumb" style={{display: 'flex', marginBottom: '30px'}}>
                  <h4 className="breadcrumb-title">Тест анализ</h4>
               </div>

               <div style={{textAlign: 'center'}}>
                  <p className="pan">Қазақстан тарихы</p>
                  <p className="pan">Оқу Сауаттылығы</p>
                  <p className="pan">Математикалық Сауаттылық</p>
                  <hr />
                  <div className="form-group">
                     <select id="pan1" className="form-control sele" onChange={(e) => changePanHandler1(parseInt(e.target.value))}>
                        <option value={0}>Пән 1</option>
                        {selectPans.map(item => {
                           if(item.id !== 4 && item.id !== 6 && item.id !== 7 && item.id !== 19){
                              return (
                                 <option key={(item.id).toString()} value={item.id}>{item.panName}</option>
                              )
                           }
                        })}
                     </select>
                  </div>
                  {/* <br /> */}
                  <div className="form-group">
                     <select id="pan2" className="form-control sele" onChange={(e) => changePanHandler2(parseInt(e.target.value))}>
                        <option value={0}>Пән 2</option>
                        {panList2 && panList2.map(item => (
                           <option key={item.panName} value={item.id}>
                              {item.panName}
                           </option>
                        ))}
                     </select>
                  </div>
                  <br />
                  <div className="form-group" style={{display: 'none'}} id="gobuttondiv">
                     <button onClick={() => navigator.onLine ? startHandler() : document.getElementById('open_connection_modal')?.click()} id="gobutton" className="btn" style={{backgroundColor: '#4c1864', color: 'white'}} disabled={loading}>
                        {loading ? 'Күте тұрыңыз...' : 'Бастау'}
                     </button>
                  </div>
                  {error && <div style={{color: 'red'}}>{error}</div>}
               </div>
            </div>

            <div style={{display: fiveId === null ? 'flex' : 'none', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 2002, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0, 0, 0, 0.7)'}}>
               <i className="fas fa-spinner fa-pulse" style={{color: 'white', fontSize: '50px'}}></i>
               <p style={{color: 'white'}}>Күте тұрыңыз</p>
            </div>
         </main>
      </>
   )
}
import { createSlice } from "@reduxjs/toolkit";
import { ISearch } from "../../helpers/models";


interface SearchDataProps {
   data: Array<ISearch>
}

const storedData = sessionStorage.getItem('searchData');
const initialState : SearchDataProps = {
   data: typeof(storedData) === 'string' ? JSON.parse(storedData) : null
}

export const searchSlice = createSlice({
   name: 'search',
   initialState,
   reducers: {
      setSearchData: (state, action) => {
         state.data = action.payload;

         sessionStorage.setItem('searchData', JSON.stringify(state.data))
      }
   }
})


export const { setSearchData } = searchSlice.actions;
export default searchSlice.reducer;
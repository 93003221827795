import React, { Component, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BASE_URL } from '../../../constants';
import { setConfirm } from '../../../redux/slices/confirmSlice';
import { setTestDataTeacher } from '../../../redux/slices/testSlice';
import { AppDispatch, RootState } from '../../../redux/store';


export const TestTeacherStart = () => {
   const [error, setError] = useState<string | null>(null);
   const [loading, setLoading] = useState(false);   

   const authData = useSelector((state : RootState) => state.auth.data);
   const dispatch = useDispatch<AppDispatch>();
   const history = useHistory();



   const startHandler = async () => {
      setLoading(true);
      setError(null);

      await fetch(`${BASE_URL}/api/kval/create`, {
         method: 'POST',
         headers: {
            'Accept': 'application/json', 'Content-Type': 'application/json'
         },
         body: JSON.stringify({
            "Id": authData?.userId
         })
      })
      .then(response => {
         if(response.ok) return response.json();
         throw new Error('*** Error startHandler() Response not OK ***' + `\n responseStatus: ${response.status}, responseStatusText: ${response.statusText}`)
      })
      .then(result => {
         setLoading(false);
         if(!result || (typeof result === 'string')){
            console.log(result);
            console.log('*** Error startHandler() Result is null or string ***')
            throw new Error('Қателік кетті! Қайталаңыз')
         }
         else {
            if(!result.state){
               throw new Error("Сізде тест тапсыруға доступ жоқ!")
            }

            if(dispatch(setTestDataTeacher(result))){
               sessionStorage.removeItem('storeTime') // для корректной работы таймера
               dispatch(setConfirm(true));

               history.push(`/Auth/TestTeacher/${result.userTestId}`);
               window.location.reload(); // для корректной работы таймера
            }
         }
      })
      .catch((e) => {
         console.log(e);
         setError(`${e}`)
         setLoading(false);
      })
      
   }


   return (
      <main className="ttr-wrapper">
         <div className="container-fluid">
            <div className="db-breadcrumb">
               <h4 className="breadcrumb-title">Ұлттық біліктілік тестілеу</h4>
            </div>

            <div className="test-div test-div-media">
               <p>
                  <strong>“Педагогика, оқыту әдістемесі”</strong> бойынша тестті бастау!
               </p>
               
               <button
                  id="hreflink"
                  className="btn widget-bg1"
                  style={{color: 'white'}}
                  onClick={() => startHandler()}
                  disabled={loading}
               >
                  {loading ? 'Күте тұрыңыз...' : 'Бастау'}
               </button>
               {loading && <i className='ml-2 fas fa-spinner fa-pulse'></i>}
               {error && <div style={{color: 'red'}}>{error}</div>}
            </div>
         </div>
      </main>
   );
};


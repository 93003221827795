import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { sidebarLinks } from '../../constants'



export const SideBar = () => {

   const history = useHistory()


   return(
      <div className="ttr-sidebar">
         <div id="sbCloseBlock" className="ttr-toggle-sidebar ttr-material-button" style={{
            width: '100vw',
            height: '100vh',
            background: 'transparent',
            display: 'none',
         }}></div>
         <div className="ttr-sidebar-wrapper content-scroll" style={{backgroundColor: '#fefefe'}}>
            <div className="ttr-sidebar-logo">
               <Link to="/">
                  {/* <img src="/assets/images/geoid-mini-logo.png" width={122} height={27}/> */}
                  <img src="/assets/images/geoid-logo.png" width={122} height={27}/>
               </Link>
            </div>

            <nav className="ttr-sidebar-navi" style={{fontWeight: 'bold'}}>
               <ul>
                  {sidebarLinks.map((link, i) => (
                     <span key={'link_' + i}>
                        {link.isNuska && <li className="ttr-seperate"></li>}
                        <li
                           // key={'link_' + i}
                           className={link.href === history.location.pathname ? "show" : ""}
                           style={{backgroundColor: link.isNuska ? '#eef' : 'inherit'}}
                        >
                           <Link to={link.href} className="ttr-material-button">
                              <span className="ttr-icon"><i className={link.icon}></i></span>
                              <span className="ttr-label">{link.text}</span>
                           </Link>
                        </li>
                     </span>
                  ))}
                  <li className="ttr-seperate"></li>
               </ul>
               {history.location.pathname.includes('TestWork') && (
                  <ul style={{marginTop: '10px'}}>
                     {!history.location.pathname.includes('OfficeTestWork') && 
                        <li>
                           <a className="ttr-material-button">
                              <span className="ttr-icon" style={{color: '#4cbd79'}}><i className="ti-layout-column2-alt"></i></span>
                              <span className="ttr-label" style={{color: '#4cbd79'}}>Дұрыс жауап</span>
                           </a>
                        </li>
                     }
                     <li>
                        <a className="ttr-material-button">
                           <span className="ttr-icon" style={{color: '#ff2b35'}}><i className="ti-layout-column2-alt"></i></span>
                           <span className="ttr-label" style={{color: '#ff2b35'}}>Белгіленген қате жауап</span>
                        </a>
                     </li>
                     <li>
                        <a className="ttr-material-button">
                           <span className="ttr-icon" style={{color: '#cdbb18'}}><i className="ti-layout-column2-alt"></i></span>
                           <span className="ttr-label" style={{color: '#cdbb18'}}>Белгіленген дұрыс жауап</span>
                        </a>
                     </li>
                  </ul>
               )}
            </nav>
         </div>
      </div>
   )
}


import { createSlice } from "@reduxjs/toolkit";
import { INuskaData } from "../../helpers/models";


interface NuskaDataProps {
   data: INuskaData | null
}

const initialState : NuskaDataProps = {
   data: null
}

export const nuskaSlice = createSlice({
   name: 'nuska',
   initialState: initialState,
   reducers: {
      setNuskaData: (state, action) => {
         state.data = action.payload;
      }
   }
})


export const { setNuskaData } = nuskaSlice.actions;
export default nuskaSlice.reducer;
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setConfirm } from "../../../redux/slices/confirmSlice";
import { setTestData } from "../../../redux/slices/testSlice";
import { BASE_URL, selectPans } from '../../../constants'
import { AppDispatch, RootState } from "../../../redux/store";
import { TarifModal } from "../../../ui/modals/TarifModal";
import { signIn } from "../../../redux/slices/authSlice";
import { getUserTarifDate } from "../../../helpers/getUserTarifDate";
import { checkUserTarifLocal } from "../../../helpers/checkUserTarifLocal";


export const Test1 = () => {
   const [selectedPanId, setSelectedPanId] = useState<number>(0)

   const [error, setError] = useState<string | null>(null);
   const [loading, setLoading] = useState(false);

   const authData = useSelector((state : RootState) => state.auth.data);
   const dispatch = useDispatch<AppDispatch>();
   const history = useHistory();


   const checkUserTarif = async () : Promise<boolean> => {
      if(checkUserTarifLocal(authData)) {
         return true;
      }
      else {
         console.log('fetch tarif');
      
         await fetch(`${BASE_URL}/api/auth/state`, {
            method: 'POST',
            headers: {
               'Accept' : 'application/json',
               'Content-Type' : 'application/json'
            },
            body: JSON.stringify({
               "UserId": authData?.userId
            })
         })
         .then(response => {
            if(response.ok) {
               return response.json()
            }
            throw new Error('*** Error checkUserTarif() Response is not OK ***')
         })
         .then(result => {
            if (result === null || (typeof result === 'string')) {
               throw new Error('*** Error checkUserTarif() Result is null or not object ***')
            }
            if (result && result.tarif >= 2 && result.tarif <= 5) {
               dispatch(signIn(result));
               if(result.testMonth === null) return false;
   
               const now = new Date();
               // console.log(new Date(result.testMonth).valueOf() - now.valueOf())
   
               if(new Date(result.testMonth).valueOf() - now.valueOf() > 0) {
                  return true;
               }
               return false;
            }
            return false;
         })
         .catch(e => {
            console.log(e);
         });
         return false;
      }
   }


   const startHandler = async () => {
      setLoading(true)
      setError(null)

      if(selectedPanId === 0){
         setLoading(false);
         return setError('Пән таңдаңыз!')
      }
      // console.log('selectedPanId: ' + selectedPanId);

      if (await checkUserTarif()) {
         await fetch(`${BASE_URL}/api/test/one`, {
            method: 'POST',
            headers: {
               'Accept': 'application/json', 'Content-Type': 'application/json'
            },
            body: JSON.stringify({
               "PanId": selectedPanId,
               "UserId": authData?.userId
            })
         })
         .then(response => {
            if(response.ok) return response.json();
            throw new Error('*** Error startHandler() Response not OK ***' + `\n responseStatus: ${response.status}, responseStatusText: ${response.statusText}`)
         })
         .then(result => {
            setLoading(false);
            if(!result || (typeof result === 'string')){
               console.log(result);
               throw new Error('*** Error startHandler() Result is null or string ***')
            }
            else {
               if(dispatch(setTestData(result))){
                  sessionStorage.removeItem('storeTime') // для корректной работы таймера
                  dispatch(setConfirm(true));

                  history.push(`/Auth/Test1/${result.id}`);
                  window.location.reload(); // для корректной работы таймера

               }
            }
         })
         .catch((e) => {
            console.log(e);
            setError(`${e}`)
         })
      }
      else {
         setLoading(false);
         document.getElementById('open_tarif_modal')?.click();
      } 
   }

   return(
      <main className="ttr-wrapper">
         <TarifModal/>
         <div className="container-fluid">
            <div className="db-breadcrumb">
               <h4 className="breadcrumb-title">Пәндік тест</h4>
            </div>
            {authData && authData.tarif >= 2 && <p>Тариф бітетін уақыт: {getUserTarifDate(authData)}</p> }

            <div className="test-div test-div-media">
               <p>Жеке пән бойынша тестті бастау!</p>
               <select className="form-control sele" onChange={(e) => setSelectedPanId(parseInt(e.target.value))}>
                  <option value={0}>Пән</option>
                  {selectPans.map(pan => (
                     <option key={pan.id.toString()} value={pan.id}>{pan.panName}</option>
                  ))}
               </select>
               <br />
               <button id="hreflink" onClick={() => startHandler()} className="test-button" disabled={loading}>
                  {loading ? 'Күте тұрыңыз...' : 'Бастау'}
               </button>
               {loading && <i className='ml-2 fas fa-spinner fa-pulse'></i>}
               {error && <div style={{color: 'red'}}>{error}</div>}
            </div>
         </div>
      </main>
   )
}
import React, { useEffect, useState } from 'react';
import { Auth } from './layouts/Auth';
import { BrowserRouter } from 'react-router-dom';
import { Content } from './layouts/Content';
import { useDispatch, useSelector } from 'react-redux';
import { setConfirm } from './redux/slices/confirmSlice';

import "react-loading-skeleton/dist/skeleton.css";
import { RootState } from './redux/store';

const App = () => {
  const authUserId = useSelector<RootState>(state => state.auth.data?.userId);
  const dispatch = useDispatch();

  document.addEventListener('contextmenu', event => event.preventDefault());
  
  const leaveConfirmation = (callback : Function) => {
    document.getElementById('open_confirm')?.click()
    document.getElementById('finish_confirm')?.addEventListener('click', function(){
      callback(true)
      dispatch(setConfirm(false))
      document.getElementById('open_confirm')?.click()
    })
  }

  return (
    <BrowserRouter
      getUserConfirmation={(_, callback) => {
        return leaveConfirmation(callback);
      }}
      basename='/'
    >
      {!authUserId ? <Auth /> : <Content />}
    </BrowserRouter>
  );
}
export default App;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../constants";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { setKursPredmets } from "../../redux/slices/predmetSlice";



//#region predmets
// const predmets = [
//    {
//       id: 1,
//       predmetName: 'География'
//    },
//    {
//       id: 2,
//       predmetName: 'Математикалық сауаттылық'
//    },
//    {
//       id: 3,
//       predmetName: 'Физика'
//    },
//    {
//       id: 33,
//       predmetName: 'Физика'
//    },
//    {
//       id: 22,
//       predmetName: 'Математикалық сауаттылық'
//    },
//    {
//       id: 11,
//       predmetName: 'География'
//    },
//    {
//       id: 333,
//       predmetName: 'Физика'
//    },
//    {
//       id: 222,
//       predmetName: 'Математикалық сауаттылық'
//    },
//    {
//       id: 111,
//       predmetName: 'География'
//    },
//    {
//       id: 3333,
//       predmetName: 'Физика'
//    },
//    {
//       id: 2222,
//       predmetName: 'Математикалық сауаттылық'
//    },
//    {
//       id: 1111,
//       predmetName: 'География'
//    },
//    {
//       id: 11111,
//       predmetName: 'География'
//    },
//    {
//       id: 33333,
//       predmetName: 'Физика'
//    },
//    {
//       id: 22222,
//       predmetName: 'Математикалық сауаттылық'
//    },
//    {
//       id: 11114,
//       predmetName: 'География'
//    },
// ]
//#endregion

export const Lessons = () => {
   const predmets = useSelector((state : RootState) => state.predmets.data);
   const dispatch = useDispatch<AppDispatch>()

   const getPredmets = async () => {
      try{
         await fetch(`${BASE_URL}/api/kurs/predmets`).then(response => {
            if (response.ok) {
               return response.json();
            }
            throw new Error('*** Error getPredmets() Response not OK ***')
         })
         .then(result => {
            console.log(result);
            if(!result || (typeof result === 'string')) {
               console.log(result);
               throw new Error('*** Error getPredmets() Result ***')
            }
            else {
               dispatch(setKursPredmets(result));
            }
         })
      }
      catch(e){
         // alert('Қателік кетті!')
         console.log(e);
      }
   }

   useEffect(() => {
      !predmets && getPredmets();
   }, [])

   return(
      <main className="ttr-wrapper">
         <div className="container-fluid">
            <div className="db-breadcrumb">
               <h4 className="breadcrumb-title">Видео-сабақтар</h4>
            </div>
            
            <div className="row">
               {predmets ? predmets.map((p, i) => {
                  let bgNumber = i + 1;

                  if(bgNumber > 5 && bgNumber < 10) bgNumber -= 5;
                  if(bgNumber > 10 && bgNumber < 15) bgNumber -= 10;
                  if(bgNumber > 15) bgNumber -= 15;

                  let bgText = "widget-bg" + bgNumber;
                  return (
                     <div key={p.id.toString()} className="col-md-6 col-lg-3 col-xl-3 col-sm-6 col-12">
                        <div className={`widget-card ${bgText}`}>
                           <Link className="wc-item" to={`/Auth/VideoGroup/${p.id}`}>
                              <h4 className="wc-title">
                                 {p.predmetName}
                              </h4>
                              <span className="wc-des" style={{color: '#fff'}}>
                                 тақырып бойынша
                              </span>
                              <span className="wc-stats" style={{color: '#fff'}}>

                              </span>
                              <div className="progress wc-progress">
                                 <div className="progress-bar" role="progressbar" style={{width: '0%'}} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}>
                                 </div>
                              </div>
                              <span className="wc-progress-bx" style={{color: '#fff'}}>
                                 <span className="wc-change">
                                    Көру
                                 </span>
                              </span>
                           </Link>
                        </div>
                     </div>
                  )
               }) : (
                  <SkeletonTheme>
                     {new Array(1, 2, 3, 4).map(x => (
                        <div key={'skeleton_' + x.toString()} className="col-md-6 col-lg-3 col-xl-3 col-sm-6 col-12">
                           <Skeleton height={115}/>
                        </div>
                     ))}
                  </SkeletonTheme>
               )}
            </div>
         </div>
      </main>
   )
}



{/* <div className="row">
   <div className="col-md-6 col-lg-3 col-xl-3 col-sm-6 col-12">
      <div className="widget-card widget-bg1">
         <Link className="wc-item" to="/Auth/VideoGroup">
            <h4 className="wc-title">
               Видео-сабақтар
            </h4>
            <span className="wc-des" style={{color: '#fff'}}>
               тақырып бойынша
            </span>
            <span className="wc-stats" style={{color: '#fff'}}>

            </span>
            <div className="progress wc-progress">
               <div className="progress-bar" role="progressbar" style={{width: '0%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <span className="wc-progress-bx" style={{color: '#fff'}}>
               <span className="wc-change">
                  Көру
               </span>
            </span>
         </Link>
      </div>
   </div>

   <div className="col-md-6 col-lg-3 col-xl-3 col-sm-6 col-12">
      <div className="widget-card widget-bg2">
         <Link to="/Auth/VideoTestGroup" className="wc-item">
            <h4 className="wc-title">
               Тест талдау 2019-2020 база
            </h4>
            <span className="wc-des" style={{color: '#fff'}}>
               Жаңа нұсқа талдаулар
            </span>

            <div className="progress wc-progress">
               <div className="progress-bar" role="progressbar" style={{width: '0%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <span className="wc-progress-bx" style={{color: '#fff'}}>
               <span className="wc-change">
                  Көру
               </span>
               <span className="wc-number ml-auto">
                  
               </span>
            </span>
         </Link>
      </div>
   </div>

   <div className="col-md-6 col-lg-3 col-xl-3 col-sm-6 col-12">
      <div className="widget-card widget-bg3">
         <Link to="/Auth/VideoGeoGroup" className="wc-item">
            <h4 className="wc-title">
               Географиялық номенклатуралар
            </h4>
            <span className="wc-des" style={{color: '#fff'}}>
               видео-сабақтар
            </span>

            <div className="progress wc-progress">
               <div className="progress-bar" role="progressbar" style={{width: '0%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <span className="wc-progress-bx" style={{color: '#fff'}}>
               <span className="wc-change">
                  Көру
               </span>
               <span className="wc-number ml-auto">
                  
               </span>
            </span>
         </Link>
      </div>
   </div>
</div> */}
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../constants";
import { ISearch } from "../../helpers/models";
import { setSearchData } from "../../redux/slices/searchSlice";
import { AppDispatch, RootState } from "../../redux/store";
import { Highlighted } from '../../ui/Highlighted'

export const Search = () => {
   const [searchState, setSearchState] = useState<ISearch[] | null>(null)
   const [searchStr, setSearchStr] = useState<string>("");
   const searchData = useSelector((state : RootState) => state.search.data)
   // const [searchStateCount, setSearchStateCount] = useState(null)
   
   const dispatch = useDispatch<AppDispatch>();


   const searchHandler = (str : string) => {
      setSearchStr(str);
      if(str.trim() === "" || str.trim().length < 3) {
         return setSearchState(null)
      }

      let arr = new Array<ISearch>;
      // let count = 0;
      
      searchData.map(x => {
         if(x.name.toLowerCase().includes(str.trim().toLowerCase())){
            arr.push(x);
            // count ++;
         }
      })
      // console.log(count);
      // setSearchStateCount(count)
      setSearchState(arr);
   }

   const loadSearchData = async () => {
      await fetch(`${BASE_URL}/api/search/get`)
      .then(response => {
         if(response.ok)
            return response.json();
         throw new Error('*** Error loadSearchData() Response not OK ***')
      })
      .then(result => {
         if(!result)
            throw new Error('*** loadSearchData() ERROR ***');
         dispatch(setSearchData(result))
      })
      .catch(e => {
         console.log(e);
      })    
   }

   useEffect(() => {
      !searchData && loadSearchData();
   }, [])

   return (
      <main className="ttr-wrapper">
         <div className="container-fluid">
            <div className="db-breadcrumb">
               <h4 className="breadcrumb-title">Сұрақ-Жауап</h4>
            </div>
            <div className="row">
               <div className="col-lg-12 m-b30">
                  <div className="widget-box">
                     <div className="email-wrapper">

                        <div className="mail-list-container">
                           <form className="mail-compose">
                              <div className="form-group col-12">
                                 <p>Сұрағыңызды жазыңыз!</p>
                                 <input className="form-control" type="text" placeholder="сұрақ" id="myInput" onChange={e => searchHandler(e.target.value)}/>
                              </div>
                           </form>

                           <hr />

                           {searchState && searchState.map(x => (
                              <div key={x.id.toString()} className="mail-list-info" data-id="myTable">
                                 <div className="mail-list-title-info">
                                    <p>
                                       <Highlighted
                                          text={x.name}
                                          highlight={searchStr}
                                       />
                                    </p>
                                    <span style={{fontWeight: 'bold', color: 'green'}}>
                                       {x.otvet}
                                    </span>
                                 </div>
                              </div>
                           ))}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </main>
   )
}
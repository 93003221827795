import React, { ChangeEvent, Dispatch, ReactNode, useEffect, useState } from "react";
import { SetStateAction } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { BASE_URL, BASE_URL_IMG } from "../../../constants";
import { RootState } from "../../../redux/store";
import { importPlayerScripts } from "../../../helpers/importScript";
import { changeQues, getTd, limitHandler } from "../../../helpers/testScript";



interface IVideo {
   vlId: string
   name: string
   number: number
   title: null
   dostupStatus: boolean
   url: string
   videoLessonGroup: null
   videoLessonGroupId: number


}
interface IVideoLessonGroup {
   video: IVideo
   items: Array<IVideo>
}

interface IVideoTest {
   number: number
   name1: string | null
   name3: string | null
   url2: string | null
   url4: string | null
   a: string
   b: string
   c: string
   d: string
   e: string
   f: string | null
   g: string | null
   h: string | null
   succus: string | null
   videoId: string
}

interface ITestItem {
   Number: number
   Otvet: string
   Score?: number
}



interface ParamTypes {
   predmetId: string
   videoGroupId: string
   videoId: string
}

export const VideoShow = () => {
   const [loading, setLoading] = useState<boolean>(false);
   const [isTestEnd, setIsTestEnd] = useState<boolean>(false);
   const [isTestSuccess, setIsTestSuccess] = useState<boolean>(false);

   // const [quality, setQuality] = useState('hd720');

   const [videoTest, setVideoTest] = useState<IVideoTest[] | null>(null);
   const [testItems, setTestItems] = useState<ITestItem[]>([]);
   const [totalBall, setTotalBall] = useState<number>(0);
   const [videoLessonGroup, setVideoLessonGroup] = useState<IVideoLessonGroup | null>(null);

   const authUserId = useSelector((state : RootState) => state.auth.data?.userId);
   const videoGroup = useSelector((state : RootState) => state.predmets.videoGroup);

   const { predmetId, videoGroupId, videoId } = useParams<ParamTypes>();

   const history = useHistory();


   const getNextVideoId = () : string => {
      if (!videoLessonGroup){
         return '';
      }
      
      const nextVideoNumber = videoLessonGroup.video.number + 1;
      const nextVideo = videoLessonGroup.items.find(x => x.number === nextVideoNumber);
      const currentVideo = videoLessonGroup.items.find(x => x.number === videoLessonGroup.video.number);
      
      if(nextVideoNumber > videoLessonGroup.items.length) {
         return currentVideo?.vlId ? currentVideo?.vlId : ''
      }
      return nextVideo?.vlId ? nextVideo?.vlId : '';
   }

   const checkTest = (number : number, otvet : string) => { // returns int
      // videoTest.find(x => x.number === number).succus = number > 8 ? 'A' : videoTest.find(x => x.number === number).succus
      const success = videoTest && videoTest.find(x => x.number === number)?.succus; 

      if(number <= 8){
         return success === otvet ? 1 : 0;
      }

      if(success?.length === 1){
         let count = 0;
         if(otvet.length === 1)
            return success.includes(otvet) ? 2 : 0;
         else if(otvet.length === 2) {
            otvet.split("").map(x => {
               count += success.includes(x) ? 1 : 0
            })
            return count === 1 ? 1 : 0;
         }
         return count;
      }
      else if(success?.length === 2) {
         let count = 0;
         if(otvet.length === 1) {
            return success.includes(otvet) ? 1 : 0;
         }
         else if(otvet.length === 2) {
            otvet.split("").map(x => {
               count += success.includes(x) ? 1 : 0
            })
            return count;
         }
         else if(otvet.length === 3) {
            otvet.split("").map(x => {
               count += success.includes(x) ? 1 : 0
            })
            return count === 2 ? 1 : 0;
         }
      }
      else if (success?.length === 3){
         let count = 0;
         if(otvet.length === 2) {
            otvet.split("").map(x => {
               count += success.includes(x) ? 1 : 0
            })
            return count === 2 ? 1 : 0;
         }
         else if(otvet.length === 3) {

            // console.log('aaa');

            otvet.split("").map(x => {
               count += success.includes(x) ? 1 : 0
            })
            
            // console.log('count = ' + count);

            if(count === 3) return 2;
            else if(count === 2) return 1;
            return 0;
         }
         return count;
      }
   }

   const checkTestHandler = async () => {
      console.log("Checking Test ... \n");

      testItems.length !== 0 && testItems.map(x => {
         x.Score = checkTest(x.Number, x.Otvet);
      })
      const userTotalBall = testItems.reduce((prev, {Score}) => Score ? prev + Score : prev, 0);
      setTotalBall(userTotalBall)

      console.log('totalBall = ' + totalBall);
      
      // console.log(getNextVideoId());


      if(userTotalBall > 9) {
         // const nextVideoId = getNextVideoId();
         try{
            await fetch(`${BASE_URL}/api/kurs/vtresult`, {
               method: 'POST',
               headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
               },
               body: JSON.stringify({
                  "UserId": authUserId,
                  "PredmetId": predmetId,
                  "VideoId": videoId ? videoId : videoLessonGroup?.video.vlId
                  // "VideoId": nextVideoId
               })
            })
            .then(response => {
               if(response.ok){
                  return response.json();
               }
               throw new Error('*** Error checkTestHandler() Response not ok ***')
            })
            .then(result => {
               if(result) {
                  console.log(result);
                  setIsTestEnd(true)
                  setIsTestSuccess(true);
               }
               else {
                  console.log(result);
               }
            })
         }
         catch (e) {
            console.log(e);
         }
      }
      else{
         setIsTestSuccess(false);
         setIsTestEnd(true);
      }
      console.log(testItems);
   }

   const checkFun = (e : ChangeEvent<HTMLInputElement>, checkedValue : string, quesNumber : number) => {
      if(limitHandler(e)){
         // console.log('limit: false');

         const testItem = testItems.find(x => x.Number === quesNumber)
         let str = testItem ? testItem?.Otvet : '';

         // if(str){
            if(quesNumber > 8) { // parseInt(e.target.name) = quesNumber
               if (e.target.checked){
                  str += checkedValue;
               }
               else{
                  for (let i = 0; i < str.length; i++) {
                     if (str[i] === checkedValue) str = str.replace(str[i], '');
                  }
               }
            }
            else {
               str = checkedValue;
            }
         // }
 

         return setTestItems(state => {
            if(state.find(x => x.Number === quesNumber)) {
               state.map(x => {
                  x.Otvet = x.Number === quesNumber ? str : x.Otvet;
               })
               return state;
            }
            else{
               return [...state, {
                  "Number" : quesNumber,
                  "Otvet": str
               }]
            }
         })
      }
   }

   const getVideoTest = async (vlId : string) => {
      setLoading(true)
      try{
         await fetch(`${BASE_URL}/api/kurs/videotest`, {
            method: 'POST',
            headers: {
               'Accept': 'application/json',
               'Content-Type': 'application/json'
            },
            body: JSON.stringify({
               "Id": vlId
            })
         })
         .then(response => {
            if(response.ok){
               return response.json();
            }
            throw new Error('*** Error getVideoTest() ***')
         })
         .then(result => {
            if(result) {
               // console.log(result);
               setVideoTest(result);
            }
            else{
               console.log(result);
               console.log('*** Error getVideoTest() Result ***');
            }
         })
      }
      catch(e){
         console.log(e);
      }
      setLoading(false)
   }

   const getVideos = async () => {
      // console.log(videoId);
      setVideoLessonGroup(null)
      try{
         await fetch(`${BASE_URL}/api/kurs/videos`, {
            method: 'POST',
            headers: {
               'Accept': 'application/json',
               "Content-Type": 'application/json'
            },
            body: JSON.stringify({
               "UserId": authUserId,
               "Id": parseInt(videoGroupId),
               "Number": videoId ? videoId : null, // videoId,
               "PredmetId": parseInt(predmetId)
            })
         })
         .then(response => {
            if(response.ok){
               return response.json();
            }
            throw new Error('*** Error getVideos() ***')
         })
         .then(result => {
            if(result){
               console.log(result);
               setVideoLessonGroup(result);
            }
            else{
               console.log(result);
               console.log('*** Error getVideos() Result ***');
            }
         })
      }
      catch(e){
         console.log(e);
      }
   }


   useEffect(() => {
      videoLessonGroup && importPlayerScripts();
   }, [videoLessonGroup])

   useEffect(() => {
      if(videoGroup?.find(x => x.id === parseInt(videoGroupId))?.openStatus){
         setVideoTest(null);
         setIsTestEnd(false);
         setTestItems([]);
         getVideos();
      }
      else{
         history.push(`/Auth/VideoGroup/${predmetId}`)
      }
   }, [videoGroupId, videoId])


   return (
      <main className="ttr-wrapper">
         <div className="container-fluid">
            <div className="db-breadcrumb">
               <h4 className="breadcrumb-title">Тақырыптық видео-сабақтар</h4>
               <ul className="db-breadcrumb-list">
                  <li>
                     <Link to={`/Auth/VideoGroup/${predmetId}`}>
                        <i className="fa fa-arrow-left"></i>Тақырыптар
                     </Link>
                  </li>
                  {/* <li>VideoGroupName</li> */}
               </ul>
            </div>
            <div className="row">
               <div className="col-lg-8 m-b30">
                  <div className="widget-box">
                     <div className="wc-title px-3">
                        <h4>
                           {videoLessonGroup
                              ? videoTest ? (
                                 <>
                                    <span className="dashbg-gray p-2 mr-2">Тест</span>
                                    {videoLessonGroup.video.name}
                                 </>
                              ) : videoLessonGroup.video.name
                              : <Skeleton style={{width: '80%'}}/>}
                           {/* {videoTest && <span>Тақырыбы бойынша тест</span>} */}
                        </h4>
                     </div>
                     <div className="widget-inner">
                        {videoTest && (
                           !isTestEnd ? (
                              <div>
                                 {videoTest.map(item => {
                                    let valueArr = [
                                       { key: 'A', value: item.a },
                                       { key: 'B', value: item.b },
                                       { key: 'C', value: item.c },
                                       { key: 'D', value: item.d },
                                       // { key: 'E', value: item.e }
                                    ];
                                    const type = item.number > 8 ? "checkbox" : "radio";
                                    type === 'checkbox' && valueArr.push(
                                       { key: 'E', value: item.e ? item.e : '' },
                                       { key: 'F', value: item.f ? item.f : '' },
                                       // { key: 'G', value: item.g ? item.g : '' },
                                       // { key: 'H', value: item.h ? item.h : '' }
                                    );
                                    return (
                                       <div className="quesWrap" key={'quesWrap_' + (item.number)} id={'select' + (item.number)} style={{display: item.number === 1 ? 'block' : 'none'}}>
                                          <p className="d-flex justify-content-between">
                                             <a className="test-number">{item.number} - сұрақ</a>
                                             {item.number === 10 && <button className="btn" onClick={() => checkTestHandler()}>Тестті аяқтау</button>}
                                          </p>
                                          {item.url2 &&
                                             <>
                                                <br/>
                                                <img src={BASE_URL_IMG + item.url2} style={{minHeight: '25vh', maxHeight: '50vh', width: 'auto', maxWidth: '100%', objectFit: 'contain'}} alt="" />
                                             </>
                                          }
                                          {item.name1 && <p className="test-text" dangerouslySetInnerHTML={{__html: item.name1}}></p>}
                                          {item.url4 && <img src={BASE_URL_IMG + item.url4} style={{minHeight: '25vh', maxHeight: '50vh', width: 'auto', maxWidth: '100%'}} alt=""/>}
                                          {item.name3 && <p className="test-text">{item.name3}</p>}

                                          {valueArr.map((x, i) => (
                                             <div key={'form_radion_btn' + x.key} className="form_radion_btn">
                                                <input type={type} id={('radidd' + item.number) + (i+1)} value={i+1} name={'ques_' + item.number} onChange={(e) => checkFun(e, x.key, item.number)}/>
                                                <label htmlFor={('radidd' + item.number) + (i+1)}>
                                                   <span style={{paddingRight: '10px'}}>{x.key} |</span>
                                                   <span dangerouslySetInnerHTML={{__html: x.value}}></span>
                                                </label>
                                                <br/>
                                             </div>
                                          ))}

                                          <div className="d-flex w-100 justify-content-between">
                                             {item.number > 1 && <button className="btn brown mr-2" onClick={() => changeQues(item.number - 1)}>
                                                <i className="fa fa-arrow-left mr-2"></i>
                                                Артқа
                                             </button>}
                                             {item.number < 10 && <button className="btn" onClick={() => changeQues(item.number + 1)}>
                                                Келесі
                                                <i className="fa fa-arrow-right ml-2"></i>
                                             </button>}
                                          </div>

                                       </div>
                                    )
                                 })}
                              </div>
                           ) : (
                              isTestSuccess ?
                              <VideoTestSuccess
                                 ball={totalBall}
                                 predmetId={parseInt(predmetId)}
                                 videoGroupId={videoGroupId}
                                 nextVideoId={getNextVideoId()}
                              />
                              : <VideoTestNotSuccess
                                 ball={totalBall}
                                 setIsTestEnd={setIsTestEnd}
                              />
                           )
                        )}
                        <div className="video" style={{display: videoTest ? 'none' : 'block'}}>
                           <div className="plyr__video-embed" id="player">
                              {videoLessonGroup && (
                                 <iframe
                                    id="plpl"
                                    // src="https://player.vimeo.com/video/739518738?h=eec891201a"
                                    src={"https://www.youtube.com/embed/" + videoLessonGroup.video.url + "&vq=hd720"}
                                    width="640" height="564"
                                    frameBorder={0}
                                    allow="fullscreen"
                                    allowFullScreen
                                 ></iframe>
                              )}
                              {/* <iframe width="966" height="543" src="https://www.youtube.com/embed/WPSADkpx1D8" title="1.1-сабақ. География ғылымына кіріспе" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                           </div>
                        </div>
                        {!videoTest && (
                           <div className="mt-3">
                              <button className="btn" onClick={() => getVideoTest(videoLessonGroup?.video.vlId ? videoLessonGroup?.video.vlId : '')}>
                                 Тақырып бойынша тест тапсыру
                              </button>
                              {loading && <i className='ml-2 fas fa-spinner fa-pulse'></i>}
                           </div>
                        )}
                     </div>
                  </div>
               </div>
               <div className="col-lg-4 m-b30">
                  <div className="widget-box">
                     <div className="wc-title px-3">
                        <h4>Сабақтар</h4>
                     </div>
                     <div className="widget-inner">
                        <div className="noti-box-list">
                           <ul>
                              {videoLessonGroup ? videoLessonGroup.items.map(item => {
                                 interface Props { children: ReactNode }
                                 const LessonWrapper = ({children} : Props) => item.dostupStatus
                                    ? <Link to={`/Auth/VideoShow/${predmetId}/${videoGroupId}/${item.vlId}`} style={{display: 'flex', alignItems: 'center'}}>
                                       {children}
                                    </Link>
                                    : <span style={{ display: 'flex', alignItems: 'center', cursor: 'default', color: '#bbb'}}>
                                       {children}
                                    </span>
                                 const icon = item.dostupStatus ? 'fa fa-video-camera' : 'fa fa-lock';

                                 return (
                                    <li key={item.vlId.toString()}>
                                       <LessonWrapper>
                                          <span className="notification-icon dashbg-gray">
                                             <i className={icon}></i>
                                          </span>
                                          <span>{item.name}</span>
                                       </LessonWrapper>
                                    </li>
                                 )
                              }) : new Array(1, 2, 3).map(x => (
                                 <li key={x.toString()} style={{display: 'flex', alignItems: 'center'}}>
                                    <span className="notification-icon dashbg-gray">
                                       <Skeleton/>
                                    </span>
                                    <span style={{width: '100%'}}>
                                       <Skeleton style={{width: '100%'}}/>
                                       <Skeleton style={{width: '70%'}}/>
                                    </span>
                                 </li>
                              ))}
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </main>
   )
}

interface VideoTestSuccessProps {
   ball: number
   predmetId: number
   videoGroupId: string
   nextVideoId: string
}

const VideoTestSuccess = ({ball, predmetId, videoGroupId, nextVideoId} : VideoTestSuccessProps) => {

   const location = useLocation();
   const total = location.pathname.split("").reduce((prev, x) => x === '/' ? prev + 1 : prev, 0);

   // useEffect(() => {
   //    console.log('tota/ = ' + total);
   // }, [])

   return (
      <div className="text-center">
         <span>
            <i className="fa fa-circle-check mb-2" style={{color: 'green', fontSize: '100px'}}></i>
         </span>
         <h3>Тест сәтті аяқталды!</h3>
         <div>
            <p>
               <strong>{ball} балл </strong>
               <span>жинадыңыз.</span>
            </p>
            <p>
               <span>Келесі видео-сабаққа доступ ашылды. Көру үшін келесі батырманы басыңыз!</span>
            </p>
            {/* <Link to={total === 4 ? `${predmetId}/${videoGroupId}/${nextVideoId}` : nextVideoId} className="btn">
               Келесі видео-сабақ
            </Link> */}
            <Link to={`/Auth/VideoGroup/${predmetId}`} className="btn">
               Келесі видео-сабақ
            </Link>
            {/* <button onClick={() => openNextVideo()} className="btn">
               Келесі видео-сабақ
            </button> */}
         </div>
      </div>
   )
}


interface VideoTestNotSuccessProps {
   ball: number
   setIsTestEnd: Dispatch<SetStateAction<boolean>>
}
const VideoTestNotSuccess = ({ball, setIsTestEnd} : VideoTestNotSuccessProps) => {

   const restartTest = () => {
      const elist = document.getElementsByTagName("input");
      for (const el of elist) {
         el.checked = false;
      }
      setIsTestEnd(false);
   }

   return (
      <div className="text-center">
         <span>
            <i className="fa fa-circle-xmark mb-2" style={{color: 'red', fontSize: '100px'}}></i>
         </span>
         <h3>Сен сынақтан өтпедің!</h3>
         <div>
            <p>
               <strong>{ball}-ақ балл </strong>
               <span>жинадың.</span>
            </p>
            <p>
               <span>Келесі видео-сабаққа доступ алу үшін тестті сәтті тапсыру керек. Ол үшін кемінде <strong>10 балл</strong> жинау міндетті!</span>
            </p>
         </div>
         <button className="btn btn-primary brown" onClick={() => restartTest()}>
            Тестті қайтадан тапсыру
         </button>
      </div>
   )
}
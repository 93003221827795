import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { BASE_URL_IMG, selectPans } from "../../../constants";
import { RootState } from "../../../redux/store";
import { changePanHandler, getReplacedQuesStr, getTd5 } from "../../../helpers/testScript";

export const TestErrorWorkOffice = () => {
   const testData = useSelector((state : RootState) => state.test.dataErrorWork5);

   const history = useHistory();

   useEffect(() => {
      if (!sessionStorage.getItem('dataErrorWork5')) {
         return history.push('/')
      }
      sessionStorage.removeItem('storeTime');
      // return () => window.location.reload();
   }, [])

   const getInputBgColor = (testId : number, forId : number, value : string) => {
      const color1 = '#4cbd79'; // дұрыс жауап
      const color2 = '#ff2b35'; // Белгіленген қате жауап
      const color3 = '#cdbb18'; // Белгіленген дұрыс жауап
      const defaultColor = '#333';

      // console.log(typeof(forId));
      // console.log(testData.items.find(x => x.testId === testId));

      const testItems = testData.items.find(x => x.testId === testId)?.items;
      const succes = testItems?.find(x => x.forId === forId)?.succes;
      const otvet = testItems?.find(x => x.forId === forId)?.otvet;

      if(otvet && succes) {
         if(otvet.includes(value)){
            if(succes.includes(value)){
               return color3;
            }
            else{
               return color2;
            }
         }
         else {
            if(succes.includes(value)){
               return color1;
            }
            return defaultColor;
         }
      }
      if(!otvet || !succes) {
         if(succes && succes.includes(value)){
            return color1;
         }
         return defaultColor;
      }
   }


   return (
      <main className="ttr-wrapper">
         <div className="container-fluid">
            <div className="db-breadcrumb err-breadcrumb">
               <div style={{display: 'flex', alignItems: 'center'}}>
                  <h4 className="breadcrumb-title">Пән:</h4>

                  <select className="form-control" onChange={(e) => changePanHandler(parseInt(e.target.value))}>
                     {testData && testData.items.map((item, index) => {
                        const panName = selectPans.find(x => x.id === item.panId)?.panName;
                        return (
                           <option key={panName} value={item.panId}>
                              {panName}
                           </option>
                        )
                     })}
                  </select>
               </div>
               <h5 style={{margin: 0}}>Қатемен жұмыс</h5>
            </div>
            {testData && (
               <>
                  <h4 className="ball-h4">
                     <span>Жинаған балыңыз: </span>
                     <span style={{color: 'mediumseagreen'}}>
                        {testData.ball} - балл
                     </span>
                  </h4>

                  {testData.items.map(item => {
                     const panName = selectPans.find(x => x.id === item.panId)?.panName;
                     return (
                        <h6 key={item.panId + 'ball' + item.ball}>
                           <span>{panName}: </span>
                           <span style={{color: 'mediumseagreen'}}>{item.ball}</span>
                        </h6>
                     )
                  })}

               </>
               
            )}
            <div style={{overflowX: 'auto'}}>
               {testData && testData.items.map((data, i) => (
                  <table key={'dataTable_' + data.panId} id={'dataTable_' + data.panId} className="test-table" style={{display: i !== 0 ? 'none' : 'flex'}}>
                     <tbody><tr>
                        {data.items.map((item, index) => (
                           <td key={item.id + (index + 1).toString()} onClick={(e) => getTd5(data.panId, e.currentTarget.innerText)} className="test-td-hover">
                              {item.forId}
                           </td>
                        ))}
                     </tr><tr>
                        {data.items.map(item => {
                           const arr = item.otvet ? item.otvet.split("") : [];

                           return (
                              <td key={'otv' + item.id} id={'otv' + item.id}>
                                 {arr.length !== 0 ? arr.map(x => (
                                    <span key={data.testId + 'arr' + x + item.forId} style={{color: getInputBgColor(data.testId, item.forId, x)}}>{x}</span>
                                 )) : ""}
                                 <span>({item.itemBall})</span>
                              </td>
                           )
                        })}
                     </tr></tbody>
                  </table>
               ))}
            </div>

            <br />

            {testData && testData.items.map((data, i) => (
               <div id={'data_' + data.panId} key={'data_' + data.panId} className="test-pole" style={{display: i !== 0 ? 'none' : 'block'}}>
                  {data.items.map((item, index) => {
                     const type = (index + 1) > 25 ? "checkbox" : "radio";
                     // console.log(item);

                     let inputArr = [
                        { key: 'A', value: item.a },
                        { key: 'B', value: item.b },
                        { key: 'C', value: item.c },
                        { key: 'D', value: item.d },
                        // { key: 'E', value: item.e }
                     ]
                     type === 'checkbox' && inputArr.push(
                        { key: 'E', value: item.e },
                        { key: 'F', value: item.f ? item.f : '' },
                        // { key: 'G', value: item.g ? item.g : '' },
                        // { key: 'H', value: item.h ? item.h : '' }
                     );

                     return (
                        <div className="quesWrap" key={'quesWrap_' + data.panId + (index+1)} id={'select_' + data.panId + (index + 1)} style={{display: index === 0 ? 'block' : 'none'}}>
                           <a className="test-number" id="test-number">{index + 1} - сұрақ</a>
                           {item.topUrl && 
                              <>
                                 <br/>
                                 <img src={BASE_URL_IMG + item.topUrl} style={{minHeight: '25vh', maxHeight: '50vh', width: 'auto', maxWidth: '100%', objectFit: 'contain'}} alt="" />
                              </>
                           }
                           {item.name && <p className="test-text" dangerouslySetInnerHTML={{__html: getReplacedQuesStr(item.name)}}></p>}
                           {item.buttomUrl && <img src={BASE_URL_IMG + item.buttomUrl} style={{minHeight: '25vh', maxHeight: '50vh', width: 'auto', maxWidth: '100%'}} alt=""/>}
                           {item.name2 && <p className="test-text" dangerouslySetInnerHTML={{__html: getReplacedQuesStr(item.name2)}}></p>}
         

                           {inputArr.map((x, i) => {
                              const color = getInputBgColor(data.testId, item.forId, x.key)
                              return (
                                 <div className="form_radion_btn" key={('radid_' + data.panId + (index + 1)) + (i+1)}>
                                    <input type={type} id={('radid_' + data.panId + (index + 1)) + (i+1)} value={i+1} name={data.panId + '_' + item.forId} disabled/>
                                    <label htmlFor={('radid_' + data.panId + (index + 1)) + (i+1)} style={{color, borderColor: color}}>
                                       <span style={{paddingRight: '10px'}}>{x.key} |</span>
                                       {item.panId === 17 ? <span>{x.value}</span> : <span dangerouslySetInnerHTML={{__html: x.value}}></span>}
                                    </label>
                                    <br/>
                                 </div>
                              )
                           })}
                        </div>
                     )
                  })}
               </div>
            ))}
         </div>
      </main>
   )
}


{/* <div className="form_radion_btn">
   <input type={type} id={('radid_' + data.panId + (index + 1)) + 1} value="1" name={data.panId + '_' + item.forId} disabled/>
   <label htmlFor={('radid_' + data.panId + (index + 1)) + 1} data-id={"A"} style={{color: getInputBgColor(data.testId, item.forId, 'A'), borderColor: getInputBgColor(data.testId, item.forId, 'A')}}>
      <span style={{paddingRight: '10px'}}>A |</span>
      <span dangerouslySetInnerHTML={{__html: item.a}}></span>
   </label>
   <br/>
</div>

<div className="form_radion_btn">
   <input type={type} id={('radid_' + data.panId + (index + 1)) + 2} value="2" name={data.panId + '_' + item.forId} disabled/>
   <label htmlFor={('radid_' + data.panId + (index + 1)) + 2} data-id={"B"} style={{color: getInputBgColor(data.testId, item.forId, 'B'), borderColor: getInputBgColor(data.testId, item.forId, 'B')}}>
      <span style={{paddingRight: '10px'}}>B |</span>
      <span dangerouslySetInnerHTML={{__html: item.b}}></span>
   </label>
   <br/>
</div>

<div className="form_radion_btn">
   <input type={type} id={('radid_' + data.panId + (index + 1)) + 3} value="3" name={data.panId + '_' + item.forId} disabled/>
   <label htmlFor={('radid_' + data.panId + (index + 1)) + 3} data-id={"C"} style={{color: getInputBgColor(data.testId, item.forId, 'C'), borderColor: getInputBgColor(data.testId, item.forId, 'C')}}>
      <span style={{paddingRight: '10px'}}>C |</span>
      <span dangerouslySetInnerHTML={{__html: item.c}}></span>
   </label>
   <br/>
</div>

<div className="form_radion_btn">
   <input type={type} id={('radid_' + data.panId + (index + 4)) + 4} value="4" name={data.panId + '_' + item.forId} disabled/>
   <label htmlFor={('radid_' + data.panId + (index + 1)) + 4} data-id={"D"} style={{color: getInputBgColor(data.testId, item.forId, 'D'), borderColor: getInputBgColor(data.testId, item.forId, 'D')}}>
      <span style={{paddingRight: '10px'}}>D |</span>
      <span dangerouslySetInnerHTML={{__html: item.d}}></span>
   </label>
   <br/>
</div>

<div className="form_radion_btn">
   <input type={type} id={('radid_' + data.panId + (index + 1)) + 5} value="5" name={data.panId + '_' + item.forId} disabled/>
   <label htmlFor={('radid_' + data.panId + (index + 1)) + 5} data-id={"E"} style={{color: getInputBgColor(data.testId, item.forId, 'E'), borderColor: getInputBgColor(data.testId, item.forId, 'E')}}>
      <span style={{paddingRight: '10px'}}>E |</span>
      <span dangerouslySetInnerHTML={{__html: item.e}}></span>
   </label>
   <br/>
</div>
{type === 'checkbox' && (
   <>
      <div className="form_radion_btn">
         <input type={type} id={('radid_' + data.panId + (index + 1)) + 6} value="6" name={data.panId + '_' + item.forId} disabled/>
         <label htmlFor={('radid_' + data.panId + (index + 1)) + 6} data-id={"F"} style={{color: getInputBgColor(data.testId, item.forId, 'F'), borderColor: getInputBgColor(data.testId, item.forId, 'F')}}>
            <span style={{paddingRight: '10px'}}>F |</span>
            <span dangerouslySetInnerHTML={{__html: item.f}}></span>
         </label>
         <br/>
      </div>

      <div className="form_radion_btn">
         <input type={type} id={('radid_' + data.panId + (index + 1)) + 7} value="7" name={data.panId + '_' + item.forId} disabled/>
         <label htmlFor={('radid_' + data.panId + (index + 1)) + 7} data-id={"G"} style={{color: getInputBgColor(data.testId, item.forId, 'G'), borderColor: getInputBgColor(data.testId, item.forId, 'G')}}>
            <span style={{paddingRight: '10px'}}>G |</span>
            <span dangerouslySetInnerHTML={{__html: item.g}}></span>
         </label>
         <br/>
      </div>

      <div className="form_radion_btn">
         <input type={type} id={('radid_' + data.panId + (index + 1)) + 8} value="8" name={data.panId + '_' + item.forId} disabled/>
         <label htmlFor={('radid_' + data.panId + (index + 1)) + 8} data-id={"H"} style={{color: getInputBgColor(data.testId, item.forId, 'H'), borderColor: getInputBgColor(data.testId, item.forId, 'H')}}>
            <span style={{paddingRight: '10px'}}>H |</span>
            <span dangerouslySetInnerHTML={{__html: item.h}}></span>
         </label>
         <br/>
      </div>
   </>
)} */}
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import { BASE_URL } from "../../constants";
import { importAuthScripts } from "../../helpers/importScript";
import ReactInputMask from 'react-input-mask'
import { useDispatch } from "react-redux";
import { signIn } from "../../redux/slices/authSlice";
import { AppDispatch } from "../../redux/store";

export const Register = () => {
   const [fio, setFio] = useState<string>('')
   const [email, setEmail] = useState<string>('');
   const [phone, setPhone] = useState<string>('');
   const [password, setPassword] = useState<string>('');
   const [passwordConfirm, setPasswordConfirm] = useState<string>('');

   const [error, setError] = useState<string | null>(null);
   const [loading, setLoading] = useState<boolean>(false);

   const dispatch = useDispatch<AppDispatch>();
   
   useEffect(() => {
      importAuthScripts();
   }, []);

   const signUpHandler = async () => {
      setLoading(true);
      setError(null);

      if(fio.trim().length < 7) {
         setLoading(false);
         return setError('Аты-жөніңізді толық жазыңыз!');
      }
      if(email.trim().length < 6 || !email.trim().includes('@')){
         setLoading(false);
         return setError('Email дұрыстап жазыңыз!');
      }
      if(phone.split("").reduce((prev, x) => x === "_" ? prev + 1 : prev, 0) > 0){
         setLoading(false);
         return setError('Телефон номер дұрыс емес!');
      }
      if(password.trim().length < 6) {
         setLoading(false);
         return setError('Пароль 6 символдан кем болмауы тиіс!');
      }
      if(password.trim() !== passwordConfirm.trim()){
         setLoading(false);
         return setError('Парольдер сәйкес келмейді!');
      }

      try{
         await fetch(`${BASE_URL}/api/auth/register`, {
            method: 'POST',
            headers: {
               'Accept': 'application/json',
               'Content-Type': 'application/json'
            },
            body: JSON.stringify({
               "Fio" : fio,
               "Email" : email,
               "Phone" : phone,
               "Password": password
            })
         })
         .then(response => {
            if(response.ok) {
               return response.json();
            }
            throw new Error('*** Error signUpHandler() ***');
         })
         .then(result => {
            setLoading(false);
            
            if (result && result.requestStatus === "100") { // "requestStatus": "100" success code
               window.location.reload();
   
               dispatch(signIn(result));
            }
            else{
               setError('Қателік кетті!');
               console.log(result);
               console.log('*** Error signUpHandler() RESULT ***');
            }
         })
      }
      catch (e) {
         console.log(e);
      }
   }

   return (
      <>
         <div className="account-form-inner">
            <div className="account-container">

               <div className="heading-bx left">
                  <h2 className="title-head">Платформаға тіркелу</h2>
                  <p>Егер тіркелген болсаңыз <Link to="/">Кіріңіз</Link></p>
               </div>

               <form className="contact-bx" asp-action="Register">
                  <div className="row placeani">
                     <div className="col-lg-12">
                        <div className="form-group">
                           <div className="input-group">
                              <label>Аты-жөніңіз</label>
                              <input
                                 value={fio}
                                 onChange={(e) => setFio(e.target.value)}
                                 className="form-control"
                                 type="text"
                                 required
                              />
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-12">
                        <div className="form-group">
                           <div className="input-group">
                              <label>Email (эл. почта)</label>
                              <input
                                 value={email}
                                 onChange={(e) => setEmail(e.target.value)}
                                 className="form-control"
                                 type="email"
                                 required
                              />
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-12">
                        <div className="form-group">
                           <div className="input-group">
                              <label>Телефон номер</label>
                              <ReactInputMask
                                 value={phone}
                                 onChange={(e) => setPhone(e.target.value)}
                                 mask='+7 (799) 999-99-99'
                                 type='tel'
                                 className="form-control"
                                 required
                              />
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-12">
                        <div className="form-group">
                           <div className="input-group">
                              <label>Жаңа пароль</label>
                              <input
                                 value={password}
                                 onChange={(e) => setPassword(e.target.value)}
                                 className="form-control"
                                 type="password"
                                 required
                              />
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-12">
                        <div className="form-group">
                           <div className="input-group">
                              <label>Парольді қайталау</label>
                              <input
                                 value={passwordConfirm}
                                 onChange={(e) => setPasswordConfirm(e.target.value)}
                                 className="form-control"
                                 type="password"
                                 required
                              />
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-12 m-b30">
                     <button type="button" onClick={() => signUpHandler()} disabled={loading} className="btn button-md">
                        {loading ? <span>Күте тұрыңыз</span> : 'Тіркелу'}
                     </button>
                     {loading && <i className='ml-2 fas fa-spinner fa-pulse'></i>}
                     {error && <p style={{color: 'red'}}>{error}</p>}
                     </div>
                  </div>
               </form>
            </div>
         </div>
      </>
   )

}
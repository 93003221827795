import { configureStore } from '@reduxjs/toolkit';

import authReducer from './slices/authSlice'
import testHistoryReducer from './slices/testHistorySlice'
import testReducer from './slices/testSlice'
import testAnswerReducer from './slices/testAnswerSlice'
import timerReducer from './slices/timerSlice'
import confirmReducer from './slices/confirmSlice'
import searchReducer from './slices/searchSlice'
import predmetReducer from './slices/predmetSlice'
import nuskaReducer from './slices/nuskaSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    testHistory: testHistoryReducer,
    test: testReducer,
    testAnswer: testAnswerReducer,
    timer: timerReducer,
    confirm: confirmReducer,
    search: searchReducer,
    predmets: predmetReducer,
    nuska: nuskaReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
import React, { ChangeEvent, CSSProperties, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Prompt, useHistory, useParams } from "react-router-dom";

import { removeTestAnswerItems5, setInitialTestAnswerItems5, setTestAnswerItems, setTestAnswerItems5 } from "../../../redux/slices/testAnswerSlice";
import { clearTimer, setTimer, setTimerInterval } from "../../../redux/slices/timerSlice";
import { changePanHandler, getReplacedQuesStr, getTd, getTd5, limitHandler } from "../../../helpers/testScript";
import { BASE_URL, BASE_URL_IMG } from "../../../constants";
import '../../../customStyles/dashstyle.css'
import { setConfirm } from "../../../redux/slices/confirmSlice";
import { setErrorWorkData5 } from "../../../redux/slices/testSlice";
import { ConfirmModal } from "../../../ui/modals/ConfirmModal";
import { RootState } from "../../../redux/store";
import { FinishTestModal } from "../../../ui/modals/FinishTestModal";
import { ErigishModal, MendeleevModal } from "../../../ui/modals/MendeleevModal";
import { HelperDropdown } from "../../../ui/HelperDropdown";
import { Calculator } from "../../../ui/Calculator";
import { FinishTestTimeoutModal } from "../../../ui/modals/FinishTestTimeoutModal";


interface Params {
   fiveId: string
}

export const Testing5 = () => {
   const [loading, setLoading] = useState<boolean>(false);

   const { fiveId } = useParams<Params>(); // fiveId

   const authUserId = useSelector((state : RootState) => state.auth.data?.userId);
   const testData = useSelector((state : RootState) => state.test.data5);

   const test5 = useSelector((state : RootState) => state.testAnswer.data5);
   const timer = useSelector((state : RootState) => state.timer)
   const confirm = useSelector((state : RootState) => state.confirm.value)

   const history = useHistory();

   const dispatch = useDispatch();

   // console.log(testItems);

   const updateCountdown = () => {
      let now = new Date();
      let diff = new Date(timer.finishTime).valueOf() - now.valueOf();
      
      let hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((diff % (1000 * 60)) / 1000);
      

      if (hours <= 0 && minutes <= 0 && seconds <= 0) {
         dispatch(clearTimer());
         document.getElementById('finishTestBtn')?.click();
         return dispatch(setTimer("00:00"))
      }
      const minutesText = minutes < 10 ? `0${minutes}` : minutes;
      const secondsText = seconds < 10 ? `0${seconds}` : seconds;
      const timerText = hours !== 0
         ? `${hours}:${minutesText}:${secondsText}`
         : `${minutesText}:${secondsText}`

      dispatch(setTimer(timerText))
   }

   const testStartHandler = () => {
      let interval = setInterval(updateCountdown, 1000)
      dispatch(setTimerInterval({interval, panId: 555})) // 555 - 5 пәндік деген сөз

      if(test5.length == 0) {
         testData && testData.items.map(item => {
            dispatch(setInitialTestAnswerItems5({
               "testId": item.id,
               "panId": item.panId
            }))
         });
      }
   }

   
   useEffect(() => {
      confirm ? testStartHandler() : history.push(`/Auth/TestWork5/${fiveId}`)
   }, [confirm])// eslint-disable-next-line react-hooks/exhaustive-deps


   const checkFun = (e: ChangeEvent<HTMLInputElement>, testId : number, panId: number, quesNumber : number, checkedValue : string) => {
      if(limitHandler(e)){
         // console.log('limit: false');
         const isChecked = e.target.checked; // isInputChecked | bool
         // const checkedValue = document.getElementById(e.target.id).nextElementSibling.getAttribute('data-id'); // A or B or C ...
         // const quesNumber = parseInt(document.getElementById(e.target.id).name);

         let str = '';
         if(test5.map(x => x.TestId).includes(testId)){
            let testItem = test5.find(x => x.TestId === testId)?.Items;
            testItem?.map(x => {
               if(x.ForId === quesNumber){
                  str = x.Otvet;
               }
            })
         }
         // console.log('str = ' + str); // console
         
         if(quesNumber > 25) {
            if (isChecked){
               str += checkedValue;
            }
            else{
               for (let i = 0; i < str.length; i++) {
                  if (str[i] === checkedValue) {
                     str = str.replace(str[i], '');
                  }
               }
            }
         }
         else {
            str = checkedValue;
         }

         dispatch(setTestAnswerItems5({
            "testId": testId,
            "panId": panId,
            "otvet" : str,
            "forId" : quesNumber
         }));
      }
      else{
         // console.log('limit: false');
      }
   }

   const testFinishHandler = async () => {
      try{
         setLoading(true);
         await fetch(`${BASE_URL}/api/five/post`, {
            method: 'POST',
            headers: {
               'Accept': 'application/json',
               'Content-Type': 'application/json'
            },
            body: JSON.stringify({
               "FiveId": parseInt(fiveId),
               "UserId": authUserId,
               "Items": test5
            })
         })
         .then((response) => {
            if (response.ok) {
            return response.json();
            }
            throw new Error('*** Error finishTest() Response not OK ***');
         })
         .then(result => {
            setLoading(false);
            if(!result || (typeof result === 'string')) {
               console.log(result);
               throw new Error('*** Error finishTest() Result is null or string ***')
            }
            else{
               dispatch(setErrorWorkData5(result));
               dispatch(removeTestAnswerItems5());
               dispatch(setConfirm(false))
               
               window.location.reload();
               // history.push(`/Auth/TestWork/${result.id}`);
            }
         })
         .catch((error) => {
            // alert('Қателік кетті!');
            console.log(error)
         })
      }
      catch(e){
         console.log(e)
      }
   }



   return (
      <>
      <Prompt when={confirm} message={(_, action) : string => {
         action === 'POP' && history.go(1)
         return 'точно баска бетке кеттің ба? Тест бітпеді ғой әлі?'
      }} />
      <main className="ttr-wrapper">
         <ConfirmModal/>
         <FinishTestModal testFinish={testFinishHandler} />
         <FinishTestTimeoutModal testFinish={testFinishHandler}/>
         <MendeleevModal/>
         <ErigishModal/>
         <Calculator/>
         <div className="container-fluid">
            <div className="db-breadcrumb" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
               <div style={{display: 'flex', alignItems: 'center'}}>
                  <h4 className="breadcrumb-title breadcrumb-title-5">Пән: </h4>
                  
                  <select className="form-control mr-1" onChange={(e) => changePanHandler(parseInt(e.target.value))}>
                     {testData && testData.items.map((item, index) => (
                        <option key={item.panName} value={item.panId}>
                           {item.panName}
                        </option>
                     ))}
                  </select>
                  
                  {/* <div id="timer" style={{width: '185px'}}>{timer ? timer.time : 'Қалған уақыт...'}</div> */}
               </div>
               <div className="tools-wrap dropdown" style={{paddingRight: '10px'}}>
                  <HelperDropdown/>
               </div>
               <div>
                  <button
                     // onClick={() => testFinishHandler()}
                     id="myBtn"
                     style={{width: '140px', padding: '7px 0', backgroundColor: 'aquamarine', border: '2px solid #4b1867'}}
                     data-toggle="modal"
                     data-target="#finishTestModal"
                  >
                     Тестті аяқтау
                  </button>
                  <button
                     id="finishTestBtn"
                     style={{display: 'none'}}
                     data-toggle="modal"
                     data-target="#finishTestTimeoutModal"
                  ></button>
               </div>
            </div>
            <div className="tools-wrap-2 dropdown" style={{marginBottom: '15px'}}>
               <HelperDropdown/>
            </div>
            <div style={{overflowX: 'auto'}}>
               {testData.items.map((data, i) => (
                  <table key={'dataTable_' + data.panId} id={'dataTable_' + data.panId} className="test-table" style={{display: i !== 0 ? 'none' : 'flex'}}>
                     <tbody><tr>
                        {data.testItems.map((item, index) => (
                           <td key={item.id + (index + 1).toString()} onClick={(e) => getTd5(data.panId, e.currentTarget.innerText)} className="test-td-hover">
                              {index + 1}
                           </td>
                        ))}
                     </tr><tr>
                        {data.testItems.map(item => {
                           let tdValue = '';
                           if(test5.map(x => x.TestId).includes(data.id)){
                              // let testItem = ;
                              test5.find(x => x.TestId === data.id)?.Items.map(x => {
                                 if(x.ForId === item.forId){
                                    tdValue = x.Otvet;
                                 }
                              })
                           }

                           return (
                              <td key={'otv' + item.id} id={'otv' + item.id}>
                                 {tdValue}
                                 {/* {testItems['panId_' + item.panId] ? testItems['panId_' + item.panId].find(x => x.ForId === item.forId) ? testItems['panId_' + item.panId].find(x => x.ForId === item.forId).Otvet : '' : ''} */}
                              </td>
                           )
                        })}
                     </tr></tbody>
                  </table>
               ))}
            </div>

            <br />
            
            {testData && testData.items.map((data, i) => (
               <div id={'data_' + data.panId} key={'data_' + data.panId} className="test-pole" style={{display: i !== 0 ? 'none' : 'block'}}>
                  {data.testItems.map((item, index) => {
                     const type = (index + 1) > 25 ? "checkbox" : "radio";
                     // let defaultCheckedValue = testItems['panId_' + item.panId] &&  testItems['panId_' + item.panId].find(x => x.ForId === item.forId) ? testItems['panId_' + item.panId].find(x => x.ForId === item.forId).Otvet : ''
                     
                     let defaultCheckedValue : string | undefined = '';
                     if(test5.find(x => x.TestId === item.id)){
                        let testItem = test5.find(x => x.TestId === item.id)?.Items;
                        defaultCheckedValue = testItem?.find(x => x.ForId === item.forId) && testItem.find(x => x.ForId === item.forId)?.Otvet;
                     }

                     let inputArr = [
                        { key: 'A', value: item.a },
                        { key: 'B', value: item.b },
                        { key: 'C', value: item.c },
                        { key: 'D', value: item.d },
                        // { key: 'E', value: item.e }
                     ]
                     type === 'checkbox' && inputArr.push(
                        { key: 'E', value: item.e },
                        { key: 'F', value: item.f ? item.f : '' },
                        // { key: 'G', value: item.g ? item.g : '' },
                        // { key: 'H', value: item.h ? item.h : '' }
                     );

                     return (
                        <div className="quesWrap" key={'quesWrap_' + data.panId + (index+1)} id={'select_' + data.panId + (index + 1)} style={{display: index === 0 ? 'block' : 'none'}}>
                           <p className="test-number">{index + 1} - сұрақ</p>
                           {item.topUrl && 
                              <>
                                 <br/>
                                 <img src={BASE_URL_IMG + item.topUrl} style={imgStyle} alt="" />
                              </>
                           }
                           {item.name && <p className="test-text" dangerouslySetInnerHTML={{__html: getReplacedQuesStr(item.name)}}></p>}
                           {item.buttomUrl && <img src={BASE_URL_IMG + item.buttomUrl} style={imgStyle} alt=""/>}
                           {item.name2 && <p className="test-text" dangerouslySetInnerHTML={{__html: getReplacedQuesStr(item.name2)}}></p>}
         

                           {inputArr.map((x, i) => (
                              <div className="form_radion_btn" key={('radid_' + data.panId + (index + 1)) + (i+1)}>
                                 <input type={type} id={('radid_' + data.panId + (index + 1)) + (i+1)} value={i+1} name={data.panId + '_' + item.forId} onChange={(e) => checkFun(e, data.id, data.panId, item.forId, x.key)} defaultChecked={defaultCheckedValue?.includes(x.key)}/>
                                 <label htmlFor={('radid_' + data.panId + (index + 1)) + (i+1)}>
                                    <span style={{paddingRight: '10px'}}>{x.key} |</span>
                                    {item.panId === 17 ? <span>{x.value}</span> : <span dangerouslySetInnerHTML={{__html: x.value}}></span>}
                                 </label>
                                 <br/>
                              </div>
                           ))}
                        </div>
                     )
                  })}
               </div>
            ))}
         </div>
         <div style={{display: loading ? 'flex' : 'none', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 2002, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0, 0, 0, 0.7)'}}>
            <i className="fas fa-spinner fa-pulse" style={{color: 'white', fontSize: '50px'}}></i>
            <p style={{color: 'white'}}>Күте тұрыңыз</p>
         </div>
      </main>
      </>
   )

}


// imgStyle TEST
// const imgStyle : CSSProperties = {
//    width: 'auto', 
//    maxWidth: '100%',

//    height: 'auto',
//    // minHeight: '100px', 
//    // maxHeight: '35vh', 
//    objectFit: 'contain'
// }

// imgStyle
const imgStyle : CSSProperties = {
   width: 'auto', 
   maxWidth: '350px', 

   height: 'auto',
   minHeight: '100px', 
   maxHeight: '35vh', 
   objectFit: 'contain'
}

import React, { useEffect } from "react";
import { Route, Link, useLocation, useHistory, Switch } from 'react-router-dom'
import { Login } from "../components/auth/Login";
import { Register } from "../components/auth/Register";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";


export const Auth = () => {
   const location = useLocation();
   const history = useHistory();

   // const dispatch = useDispatch();
   const authUserId = useSelector((state : RootState) => state.auth.data?.userId);

   useEffect(() => {
      !authUserId && history.replace('/');
   }, [authUserId])


   return (
      <div className="page-wraper">
         {/* <div id="loading-icon-bx"></div> */}
         <div className="account-form">
            <div className="account-head" style={{
               backgroundImage: 'url("../assets/images/background/bg22.jpeg")'
            }}>
               <Link to={location.pathname}>
                  <img src="../assets/images/geoid-logo-white.png" alt="" />
               </Link>
            </div>
            <Switch>
               <Route path='/' exact component={Login}/>
               <Route path='/register' exact component={Register}/>
            </Switch>
         </div>
      </div>
   )
}
import { createSlice } from "@reduxjs/toolkit";

interface TestAnswerItems {
   ForId: Number
   Otvet: string
}
interface TestAnswerItems5 {
   TestId: number
   PanId: number
   Items: TestAnswerItems[]
}
interface TestAnswerItemsNuska {
   PanId: number
   Items: TestAnswerItems[]
}

interface ITestAnswer {
   data: Array<TestAnswerItems>
   data5: Array<TestAnswerItems5>
   dataNuska: Array<TestAnswerItemsNuska>
   dataTeacher: Array<TestAnswerItems>
}



const storedData = sessionStorage.getItem('userTestItems')
const storedData5 = sessionStorage.getItem('userTestItems5')
const storedDataNuska = sessionStorage.getItem('userTestItemsNuska')
const storedDataTeacher = sessionStorage.getItem('userTestItemsTeacher')

const initialState : ITestAnswer = {
   data: (typeof storedData === 'string' ? JSON.parse(storedData) : []) ?? [],
   data5: (typeof storedData5 === 'string' ? JSON.parse(storedData5) : []) ?? [],
   dataNuska: (typeof storedDataNuska === 'string' ? JSON.parse(storedDataNuska) : []) ?? [],
   dataTeacher: (typeof storedDataTeacher === 'string' ? JSON.parse(storedDataTeacher) : []) ?? []
}


export const testAnswerSlice = createSlice({
   name: 'testAnswer',
   initialState,
   reducers: {
      setTestAnswerItems: (state, action) => {
         if(state.data.map(x => x.ForId).includes(action.payload.ForId)){
            state.data.map(x => {
               if(x.ForId === action.payload.ForId) x.Otvet = action.payload.Otvet;
            })
         }
         else{
            state.data = [...state.data, action.payload];
         }
         sessionStorage.setItem('userTestItems', JSON.stringify(state.data));
      },
      setInitialTestAnswerItems5: (state, action) => {
         state.data5.push({
            TestId: action.payload.testId,
            PanId: action.payload.panId,
            Items: []
         })
         sessionStorage.setItem('userTestItems5', JSON.stringify(state.data5));
      },
      setInitialTestAnswerItemsNuska: (state, action) => {
         state.dataNuska.push({
            PanId: action.payload.panId,
            Items: []
         })
         sessionStorage.setItem('userTestItemsNuska', JSON.stringify(state.dataNuska));
      },
      setTestAnswerItems5: (state, action) => {
         // state.data5.fiveId = action.payload.fiveId;
         // console.log(action);

         let answerObj = {
            Otvet: action.payload.otvet,
            ForId: action.payload.forId
         }

         if(state.data5.length !== 0){
            let testItem = state.data5.find(x => x.TestId === action.payload.testId)
               ? state.data5.find(x => x.TestId === action.payload.testId)?.Items
               : null;

            if(testItem){
               if(testItem.map(x => x.ForId).includes(action.payload.forId)){
                  state.data5.find(x => x.TestId === action.payload.testId)?.Items.map(x => {
                     if(x.ForId === action.payload.forId){
                        x.Otvet = action.payload.otvet;
                     }
                  })
               }
               else{
                  state.data5.find(x => x.TestId === action.payload.testId)?.Items.push(answerObj);
                  // state.data5.find(x => x.TestId === action.payload.testId)?.Items = [...state.data5.find(x => x.TestId === action.payload.testId)?.Items, answerObj];
               }
            }
            else{
               state.data5.push({
                  TestId: action.payload.testId,
                  PanId: action.payload.panId,
                  Items: [answerObj]
               })
            }
         }
         else{
            state.data5.push({
               TestId: action.payload.testId,
               PanId: action.payload.panId,
               Items: [answerObj]
            })
         }
         sessionStorage.setItem('userTestItems5', JSON.stringify(state.data5));
      },
      setTestAnswerItemsNuska: (state, action) => {
         let answerObj = {
            Otvet: action.payload.otvet,
            ForId: action.payload.forId
         }

         if(state.dataNuska.length !== 0){
            let testItem = state.dataNuska.find(x => x.PanId === action.payload.panId)
               ? state.dataNuska.find(x => x.PanId === action.payload.panId)?.Items
               : null;

            if(testItem){
               if(testItem.map(x => x.ForId).includes(action.payload.forId)){
                  state.dataNuska.find(x => x.PanId === action.payload.panId)?.Items.map(x => {
                     if(x.ForId === action.payload.forId){
                        x.Otvet = action.payload.otvet;
                     }
                  })
               }
               else{
                  state.dataNuska.find(x => x.PanId === action.payload.panId)?.Items.push(answerObj);
                  // state.dataNuska.find(x => x.PanId === action.payload.panId)?.Items = [...state.dataNuska.find(x => x.TestId === action.payload.testId)?.Items, answerObj];
               }
            }
            else{
               state.dataNuska.push({
                  PanId: action.payload.panId,
                  Items: [answerObj]
               })
            }
         }
         else{
            state.dataNuska.push({
               PanId: action.payload.panId,
               Items: [answerObj]
            })
         }
         sessionStorage.setItem('userTestItemsNuska', JSON.stringify(state.dataNuska));
      },
      setTestAnswerItemsTeacher: (state, action) => {
         if(state.dataTeacher.map(x => x.ForId).includes(action.payload.ForId)){
            state.dataTeacher.map(x => {
               if(x.ForId === action.payload.ForId) x.Otvet = action.payload.Otvet;
            })
         }
         else{
            state.dataTeacher = [...state.dataTeacher, action.payload];
         }
         sessionStorage.setItem('userTestItemsTeacher', JSON.stringify(state.dataTeacher));
      },

      removeTestAnswerItems: (state) => {
         state.data = [];
         sessionStorage.removeItem('userTestItems');
      },
      removeTestAnswerItems5: (state) => {
         state.data5 = [];
         sessionStorage.removeItem('userTestItems5');
      },
      removeTestAnswerItemsNuska: (state) => {
         state.dataNuska = [];
         sessionStorage.removeItem('userTestItemsNuska');
      },
      removeTestAnswerItemsTeacher: (state) => {
         state.dataTeacher = [];
         sessionStorage.removeItem('userTestItemsTeacher');
      },
   }
});

export const { 
   setTestAnswerItems, setTestAnswerItems5, setTestAnswerItemsNuska, setTestAnswerItemsTeacher, 
   setInitialTestAnswerItems5, setInitialTestAnswerItemsNuska,
   removeTestAnswerItems, removeTestAnswerItems5, removeTestAnswerItemsNuska, removeTestAnswerItemsTeacher
} = testAnswerSlice.actions;
export default testAnswerSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { PredmetProps } from "../../helpers/models";


const storeVideoGroup = sessionStorage.getItem('videoGroup');

const initialState : PredmetProps = {
   data: null,
   videoGroup: typeof storeVideoGroup === 'string' ? JSON.parse(storeVideoGroup) : null
}

export const predmetSlice = createSlice({
   name: 'predmets',
   initialState,
   reducers: {
      setKursPredmets: (state, action) => {
         state.data = action.payload;
      },
      setKursVideoGroup: (state, action) => {
         state.videoGroup = action.payload;
         sessionStorage.setItem('videoGroup', JSON.stringify(state.videoGroup));
      }
   }
})


export const { setKursPredmets, setKursVideoGroup } = predmetSlice.actions;
export default predmetSlice.reducer;
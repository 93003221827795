import { createSlice } from "@reduxjs/toolkit";
import { IAuthData } from "../../helpers/models";




interface authProps {
   data: IAuthData | null
}
const storedData = localStorage.getItem('authUserData');
const initialState : authProps = {
   data: typeof storedData === 'string' ? JSON.parse(storedData) : null
}


export const authSlice = createSlice({
   name: 'auth',
   initialState,
   reducers: {
      signIn: (state, action)=> {
         state.data = action.payload;
         localStorage.setItem('authUserData', JSON.stringify(action.payload))
      },
      signOut: (state) => {
         state.data = null;
         localStorage.removeItem('authUserData');
         localStorage.removeItem('expireAuthTime');
      },
      setSignDate: (state, action) => {
         const now = new Date(action.payload.toLocaleString());
      },
      setFioWithModal: (state, action) => {
         if(state.data) {
            state.data.fio = action.payload;
            localStorage.setItem('authUserData', JSON.stringify(state.data))
         }
      }
   }
})

export const { signIn, signOut, setSignDate, setFioWithModal } = authSlice.actions;
export default authSlice.reducer;
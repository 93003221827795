import React from "react";

export const ConnectionModal = () => {
   return (
      <> 
         <button style={{display: 'none'}} id="open_connection_modal" type="button" className="btn btn-primary" data-toggle="modal" data-target="#connectionModal">
            Launch demo modal
         </button>

         <div className="modal fade" id="connectionModal" tabIndex={-1} role="dialog" aria-labelledby="connectionModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
               <div className="modal-content">
                  <div className="modal-header">
                  <h5 className="modal-title" id="connectionModalLabel">Ескерту!</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => !navigator.onLine && setTimeout(() => {
                        document.getElementById('open_connection_modal')?.click()
                     }, 500)}>
                     <span aria-hidden="true">&times;</span>
                  </button>
                  </div>
                  <div className="modal-body">
                     <p>Интернет байланысын тексеріңіз!</p>
                  </div>
                  <div className="modal-footer" >
                     <button id="close_connection_modal" type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => !navigator.onLine && setTimeout(() => {
                        document.getElementById('open_connection_modal')?.click()
                     }, 500)}>OK</button>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}
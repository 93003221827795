import { createSlice } from "@reduxjs/toolkit";

interface confirmProps {
   value: boolean
}

const storedConfirm = sessionStorage.getItem('confirm');

const initialState : confirmProps = {
   value: typeof storedConfirm === 'string' ? JSON.parse(storedConfirm) : true
}

export const confirmSlice = createSlice({
   name: 'confirm',
   initialState,
   reducers: { 
      setConfirm: (state, action) => {
         // console.log(state);
         state.value = action.payload;
         sessionStorage.setItem('confirm', JSON.stringify(state.value));
      }
   }
})


export const { setConfirm } = confirmSlice.actions;
export default confirmSlice.reducer;
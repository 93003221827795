// export const BASE_URL = 'http://geodeveloper-001-site1.dtempurl.com' // base url test
export const BASE_URL = 'https://geo.gdh.kz/oldgeo';
export const BASE_URL_IMG = 'https://geo.gdh.kz/oldgeo';

export const BASE_URL_OFFICE = 'https://geo.gdh.kz/newgeo';

export const BASE_URL_ANALIZ = 'https://geo.gdh.kz/analizapi';
export const BASE_URL_ANALIZ_IMG = 'https://geo.gdh.kz/analiz';

export const BASE_URL_NUSKA = 'https://geo.gdh.kz/nuska/api';
export const BASE_URL_NUSKA_IMG = 'https://geo.gdh.kz/nuska';



export const selectPans = [
   {id: 1,  panName: 'География'},
   {id: 2,  panName: 'Биология'},
   {id: 3,  panName: 'Химия'},
   {id: 4,  panName: 'Қазақстан Тарихы'},
   {id: 6,  panName: 'Математикалық сауаттылық'},
   {id: 7,  panName: 'Оқу сауаттылығы'},
   {id: 8,  panName: 'Математика'},
   {id: 9,  panName: 'Физика'},
   {id: 10, panName: 'Дүниежүзі тарихы'},
   {id: 11, panName: 'Құқық негіздері'},
   {id: 12, panName: 'Қазақ тілі'},
   {id: 13, panName: 'Қазақ әдебиеті'},
   {id: 14, panName: 'Орыс тілі'},
   {id: 15, panName: 'Ағылшын'},
   // {id: 16, panName: 'География NEW'}
   {id: 17, panName: 'Информатика'},
   {id: 19, panName: 'Бастауыш сынып'}
]


export const sidebarLinks = [
   {
      href: '/Auth/Account',
      icon: 'ti-home',
      text: 'Жеке Кабинет'
   },
   {
      href: '/Auth/Profil',
      icon: 'ti-book',
      text: 'Сабақтар'
   },
   {
      href: '/Auth/Test5',
      icon: 'ti-check-box',
      text: 'ҰБТ тапсыру'
   },
   {
      href: '/Auth/Test1',
      icon: 'ti-pencil-alt',
      text: 'Пәндік тест тапсыру'
   },
   {
      href: '/Auth/Search',
      icon: 'ti-search',
      text: 'Сұрақ-жауап'
   },
   // {
   //    href: '/Auth/TestTeacher',
   //    icon: 'ti-stats-up',
   //    text: 'Ұлттық біліктілік тестілеу',
   //    isTeacher: true
   // },
   {
      href: '/Auth/TestAnaliz',
      icon: 'ti-pencil-alt',
      text: 'Тест Анализ'
   },
   {
      href: '/Auth/TestNuska',
      icon: 'ti-check-box',
      text: 'Тест Нұсқа',
      isNuska: true
   }
]


export const REQUEST_STATUSES = [
   {key: 100, value: 'Успешно зарегистрировано'},
   {key: 101, value: 'Данный электронный почта уже зарегистрировано в нашем системе'},
   {key: 102, value: 'Ошибка в пароле. Длина пароля должен превышать 5 символов'},
   {key: 103, value: 'Успешно авторизовано'},
   {key: 104, value: 'Неправильный логин или пароль'},
   {key: 105, value: 'Данный эл почта не зарегистрировано'},
   {key: 106, value: 'User не найдено. Ошибка в UserId'},
   {key: 107, value: 'Сіздің аккаунтіңіз бірнеше рет қолданылған. Жүйеге кіру үшін мына нөмірге жазыңыз 8 747 398 7068'},
   
   {key: 200, value: 'Success'},
   {key: 201, value: 'User не имеет доступ'},
   
   {key: 301, value: 'Бір пәндік тест тапсыру лимиті бітті'},
   {key: 302, value: '5 пәндік тест тапсыру лимиті бітті'}
]


// authUserData | {"userId":"821c3c73-04d8-4221-8948-c1491e7cca9c","userName":"Developer@mail.ru","fio":"Bahon","phone":"77007761301","tarif":3,"lastTestData":null,"testMonth":"2023-01-03T00:36:59.4352819","videofl":false,"fiveLimit":0,"oneLimit":0,"requestStatus":"103"}

// Developer@mail.ru    |      authUserData |

// {
//    "userId":"821c3c73-04d8-4221-8948-c1491e7cca9c",
//    "userName":"Developer@mail.ru",
//    "fio":"Bahon",
//    "phone":"77007761301",
//    "tarif":2,
//    "lastTestData":null,
//    "testMonth":"2022-10-01T21:18:59.5238022",
//    "videofl":false,
//    "requestStatus":"103"
// }
import React from "react";


interface Props {
   testFinish: () => void
}

export const FinishTestModal = ({testFinish} : Props) => {
   return (
      <>
         <div className="modal fade" id="finishTestModal" tabIndex={-1} role="dialog" aria-labelledby="finishTestModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
               <div className="modal-content">
                  <div className="modal-header">
                  <h5 className="modal-title" id="finishTestModalLabel">Тестті аяқтау</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                  </button>
                  </div>
                  <div className="modal-body">
                     <p>Сіз тестті аяқтағыңыз келеді ме?</p>
                  </div>
                  <div className="modal-footer">
                     <button type="button" className="btn gray" data-dismiss="modal">Жоқ</button>
                     <button type="button" className="btn" data-dismiss="modal" onClick={() => navigator.onLine ? testFinish() : document.getElementById('open_connection_modal')?.click()}>Иә, тестті аяқтаймын</button>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}
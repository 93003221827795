import React, { ChangeEvent, CSSProperties, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Prompt, useHistory, useParams } from "react-router-dom";

import { removeTestAnswerItems5, removeTestAnswerItemsNuska, setInitialTestAnswerItemsNuska, setTestAnswerItems, setTestAnswerItems5, setTestAnswerItemsNuska } from "../../../redux/slices/testAnswerSlice";
import { clearTimer, setTimer, setTimerInterval } from "../../../redux/slices/timerSlice";
import { changePanHandler, getReplacedQuesStr, getTd, getTd5, lfunc, limitHandler } from "../../../helpers/testScript";
import { BASE_URL, BASE_URL_ANALIZ, BASE_URL_ANALIZ_IMG, BASE_URL_IMG, BASE_URL_NUSKA, BASE_URL_NUSKA_IMG } from "../../../constants";
import '../../../customStyles/dashstyle.css'
import { setConfirm } from "../../../redux/slices/confirmSlice";
import { setErrorWorkData5, setErrorWorkDataNuska } from "../../../redux/slices/testSlice";
import { RootState } from "../../../redux/store";

import { ConfirmModal } from "../../../ui/modals/ConfirmModal";
import { FinishTestModal } from "../../../ui/modals/FinishTestModal";
import { ErigishModal, MendeleevModal } from "../../../ui/modals/MendeleevModal";
import { HelperDropdown } from "../../../ui/HelperDropdown";
import { Calculator } from "../../../ui/Calculator";
import { FinishTestTimeoutModal } from "../../../ui/modals/FinishTestTimeoutModal";


interface Params {
   testId: string
}

// interface ICurrentOther {
//    index: number
//    forId: number | null
// }
// const InitialCurrentOther : ICurrentOther = {
//    index: 0,
//    forId: 36
// }

export const TestingNuska = () => {
   const [loading, setLoading] = useState<boolean>(false);
   // const [currentOther, setCurrentOther] = useState<ICurrentOther>(InitialCurrentOther)

   const { testId } = useParams<Params>();

   const authUser = useSelector((state : RootState) => state.auth.data);
   const testData = useSelector((state : RootState) => state.test.dataNuska);

   const testNuska = useSelector((state : RootState) => state.testAnswer.dataNuska);
   const timer = useSelector((state : RootState) => state.timer)
   const confirm = useSelector((state : RootState) => state.confirm.value)

   const history = useHistory();

   const dispatch = useDispatch();

   // console.log(testItems);

   const updateCountdown = () => {
      let now = new Date();
      let diff = new Date(timer.finishTime).valueOf() - now.valueOf();
      
      let hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((diff % (1000 * 60)) / 1000);

      if (hours <= 0 && minutes <= 0 && seconds <= 0) {
         dispatch(clearTimer());
         document.getElementById('finishTestBtn')?.click();
         return dispatch(setTimer("00:00"))
      }
      
      const minutesText = minutes < 10 ? `0${minutes}` : minutes;
      const secondsText = seconds < 10 ? `0${seconds}` : seconds;
      const timerText = hours !== 0
         ? `${hours}:${minutesText}:${secondsText}`
         : `${minutesText}:${secondsText}`

      dispatch(setTimer(timerText))
   }

   const testStartHandler = () => {
      let interval = setInterval(updateCountdown, 1000)
      dispatch(setTimerInterval({interval, panId: 55555}))


      if(testNuska.length == 0) {
         testData && testData.items.map((item, i) => {
            dispatch(setInitialTestAnswerItemsNuska({
               "panId": item.panId
            }))
         });
      }
   }
   
   useEffect(() => {
      // console.log(testData);

      confirm ? testStartHandler() : history.push(`/Auth/TestWorkNuska/${testId}`)
   }, [confirm])// eslint-disable-next-line react-hooks/exhaustive-deps


   const checkFun = (e: any, panId: number, quesNumber : number, checkedValue : string) => {
      if(limitHandler(e)){
         // console.log('limit: false');
         const isChecked = e.target.checked; // isInputChecked | bool
         // const checkedValue = document.getElementById(e.target.id).nextElementSibling.getAttribute('data-id'); // A or B or C ...
         // const quesNumber = parseInt(document.getElementById(e.target.id).name);

         let str = '';
         if(testNuska.map(x => x.PanId).includes(panId)){
            let testItem = testNuska.find(x => x.PanId === panId)?.Items;
            testItem?.map(x => {
               if(x.ForId === quesNumber){
                  str = x.Otvet;
               }
            })
         }
         // console.log('str = ' + str); // console
         
         if(quesNumber > 30) {
            if (isChecked){
               str += checkedValue;
            }
            else{
               for (let i = 0; i < str.length; i++) {
                  if (str[i] === checkedValue) {
                     str = str.replace(str[i], '');
                  }
               }
            }
         }
         else {
            str = checkedValue;
         }

         dispatch(setTestAnswerItemsNuska({
            "panId": panId,
            "otvet" : str,
            "forId" : quesNumber
         }));
      }
      else{
         console.log('limit: false');
      }
   }

   const checkFunOther = (e : React.MouseEvent<HTMLLIElement, MouseEvent>,otherIndex : number, panId: number, quesNumber : number, checkedValue : string, otherId: number) => {
      lfunc(e, otherId);
      
      let str = '  ';
      if(testNuska.map(x => x.PanId).includes(panId)){
         let testItem = testNuska.find(x => x.PanId === panId)?.Items;
         testItem?.map(x => {
            if(x.ForId === quesNumber){
               str = x.Otvet;
            }
         })
      }
      
      str = str.slice(0, otherIndex) + checkedValue + str.slice(otherIndex + checkedValue.length)

      dispatch(setTestAnswerItemsNuska({
         "panId": panId,
         "otvet" : str,
         "forId" : quesNumber
      }));
   }

   const testFinishHandler = async () => {
      setLoading(true);
      // console.log(testNuska); // console

      console.log(testId);
      // console.log(authUser?.userId);
      
      await fetch(`${BASE_URL_NUSKA}/quiz/result`, {
      // await fetch(`http://172.20.10.2:45456/api/quiz/result`, {
         method: 'POST',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
         },
         body: JSON.stringify({
            "TestId": parseInt(testId),
            "UserId": authUser?.userId,
            "Fio": authUser?.fio,
            "NuskaId": testData.nuskaId,
            "Items": testNuska
         })
      })
      .then((response) => {
         if (response.ok) {
           return response.json();
         }
         throw new Error('*** Error finishTest() Response not OK ***');
      })
      .then(result => {
         setLoading(false);
         // console.log(result);
         if(!result || (typeof result === 'string')) {
            console.log(result);
            throw new Error('*** Error finishTest() Result is null or string ***')
         }
         else{
            // console.log(result)
            dispatch(setErrorWorkDataNuska(result));
            dispatch(removeTestAnswerItemsNuska());
            dispatch(setConfirm(false))
            
            window.location.reload();
            history.push(`/Auth/TestWorkNuska/${result.testId}`);
         }
      })
      .catch((error) => {
         // alert('Қателік кетті!');
         console.log(error)
      })
   }



   return (
      <>
      <Prompt when={confirm} message={(_, action) : string => {
         action === 'POP' && history.go(1)
         return 'точно баска бетке кеттің ба? Тест бітпеді ғой әлі?'
      }} />
      <main className="ttr-wrapper">
         <ConfirmModal/>
         <FinishTestModal testFinish={testFinishHandler} />
         <FinishTestTimeoutModal testFinish={testFinishHandler}/>
         <MendeleevModal/>
         <ErigishModal/>
         <Calculator/>
         <div className="container-fluid">
            <div className="db-breadcrumb" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
               <div style={{display: 'flex', alignItems: 'center'}}>
                  <h4 className="breadcrumb-title breadcrumb-title-5">Пән: </h4>
                  
                  <select className="form-control mr-1" onChange={(e) => changePanHandler(parseInt(e.target.value))}>
                     {testData && testData.items.map((item, index) => (
                        <option key={item.panName} value={item.panId}>
                           {item.panName}
                        </option>
                     ))}
                  </select>
                  
                  {/* <div id="timer" style={{width: '185px'}}>{timer ? timer.time : 'Қалған уақыт...'}</div> */}
               </div>
               <div className="tools-wrap dropdown" style={{paddingRight: '10px'}}>
                  <HelperDropdown/>
               </div>
               <div>
                  <button
                     // onClick={() => testFinishHandler()}
                     id="myBtn"
                     style={{width: '140px', padding: '7px 0', backgroundColor: 'aquamarine', border: '2px solid #4b1867'}}
                     data-toggle="modal"
                     data-target="#finishTestModal"
                  >
                     Тестті аяқтау
                  </button>
                  <button
                     id="finishTestBtn"
                     style={{display: 'none'}}
                     data-toggle="modal"
                     data-target="#finishTestTimeoutModal"
                  ></button>
               </div>
            </div>
            <div className="tools-wrap-2 dropdown" style={{marginBottom: '15px'}}>
               <HelperDropdown/>
            </div>
            <div style={{overflowX: 'auto'}}>
               {testData.items?.map((data, i) => (
                  <table key={'dataTable_' + data.panId} id={'dataTable_' + data.panId} className="test-table" style={{display: i !== 0 ? 'none' : 'flex'}}>
                     <tbody><tr>
                        {data.items.map((item, index) => (
                           <td key={item.id + (index + 1).toString()} onClick={(e) => getTd5(data.panId, e.currentTarget.innerText)} className="test-td-hover">
                              {index + 1}
                           </td>
                        ))}
                     </tr><tr>
                        {data.items.map(item => {
                           let tdValue = '';
                           if(testNuska.map(x => x.PanId).includes(data.panId)){
                              // let testItem = ;
                              testNuska.find(x => x.PanId === data.panId)?.Items.map(x => {
                                 if(x.ForId === item.forId){
                                    tdValue = x.Otvet;
                                 }
                              })
                           }

                           return (
                              <td key={'otv' + item.id} id={'otv' + item.id}>
                                 {tdValue}
                                 {/* {testItems['panId_' + item.panId] ? testItems['panId_' + item.panId].find(x => x.ForId === item.forId) ? testItems['panId_' + item.panId].find(x => x.ForId === item.forId).Otvet : '' : ''} */}
                              </td>
                           )
                        })}
                     </tr></tbody>
                  </table>
               ))}
            </div>

            <br />
            
            {testData && testData.items.map((data, i) => (
               <div id={'data_' + data.panId} key={'data_' + data.panId} className="test-pole" style={{display: i !== 0 ? 'none' : 'block'}}>
                  {data.items.map((item, index) => {
                     const type = (index + 1) > 30 ? "checkbox" : "radio";
                     // let defaultCheckedValue = testItems['panId_' + item.panId] &&  testItems['panId_' + item.panId].find(x => x.ForId === item.forId) ? testItems['panId_' + item.panId].find(x => x.ForId === item.forId).Otvet : ''
                     
                     let defaultCheckedValue : string | undefined = '';
                     if(testNuska.find(x => x.PanId === data.panId)){
                        let testItem = testNuska.find(x => x.PanId === data.panId)?.Items;
                        defaultCheckedValue = testItem?.find(x => x.ForId === item.forId) && testItem.find(x => x.ForId === item.forId)?.Otvet;
                     }

                     let other : string = '';
                     if((index + 1) > 35){
                        if(defaultCheckedValue && defaultCheckedValue?.length > 0){
                           other = defaultCheckedValue;
                        }
                     }

                     let inputArr = [
                        { key: 'A', value: item.a },
                        { key: 'B', value: item.b },
                        { key: 'C', value: item.c },
                        { key: 'D', value: item.d },
                        // { key: 'E', value: item.e }
                     ]
                     type === 'checkbox' && inputArr.push(
                        { key: 'E', value: item.e },
                        { key: 'F', value: item.f ? item.f : '' },
                        // { key: 'G', value: item.g ? item.g : '' },
                        // { key: 'H', value: item.h ? item.h : '' }
                     );

                     return (
                        <div className="quesWrap" key={'quesWrap_' + data.panId + (index+1)} id={'select_' + data.panId + (index + 1)} style={{display: index === 0 ? 'block' : 'none'}}>
                           <p className="test-number">{index + 1} - сұрақ</p>
                           {item.topUrl && 
                              <>
                                 <br/>
                                 <img src={BASE_URL_NUSKA_IMG + item.topUrl} style={imgStyle} alt="" />
                              </>
                           }
                           {item.name && <p className="test-text" dangerouslySetInnerHTML={{__html: getReplacedQuesStr(item.name)}}></p>}
                           {item.buttomUrl && <img src={BASE_URL_NUSKA_IMG + item.buttomUrl} style={imgStyle} alt=""/>}
                           {item.name2 && <p className="test-text" dangerouslySetInnerHTML={{__html: getReplacedQuesStr(item.name2)}}></p>}
         

                           {(index + 1) < 36 && inputArr.map((x, i) => (
                              <div className="form_radion_btn" key={('radid_' + data.panId + (index + 1)) + (i+1)}>
                                 <input type={type} id={('radid_' + data.panId + (index + 1)) + (i+1)} value={i+1} name={'name_' + data.panId + '_' + item.forId} onChange={(e) => checkFun(e, data.panId, item.forId, x.key)} defaultChecked={defaultCheckedValue?.includes(x.key)}/>
                                 <label htmlFor={('radid_' + data.panId + (index + 1)) + (i+1)}>
                                    <span style={{paddingRight: '10px'}}>{x.key} |</span>
                                    {item.panId === 17 ? <span>{x.value}</span> : <span dangerouslySetInnerHTML={{__html: x.value}}></span>}
                                 </label>
                                 <br/>
                              </div>
                           ))}

                           {item.other_35_40.length > 0 && item.other_35_40.map((x, i) => {
                              let optionArr = [
                                 { key: 'A', value: x.a },
                                 { key: 'B', value: x.b },
                                 { key: 'C', value: x.c },
                                 { key: 'D', value: x.d },
                              ]

                              const ov = optionArr.find(x => x.key === other[i]);
                              const ovv : string = ov && ov.value != null ? ov.value : "Таңдау";

                              return (
                                 <div className="form-group d-flex" key={'other_' + i}>
                                    <label style={{display: 'block',maxWidth: '30%'}}>
                                       <span dangerouslySetInnerHTML={{__html: x.name ? x.name : ''}}></span>
                                    </label>

                                    {/* <select className="form-control sele" onChange={(e) => checkFunOther(i, data.panId, item.forId, e.target.value)} defaultValue={other[i]}>
                                       <option value=" ">Таңдау</option>
                                       {optionArr.map((x, i) => (
                                          <option key={x.key+i} value={x.key} dangerouslySetInnerHTML={{__html: x.key + ') ' + x.value}}></option>
                                       ))}
                                    </select> */}

                                    <ul className={i === 0 ? "list-unstyled list-unstyled-0 " + "list-unstyled-" + x.id : "list-unstyled " + "list-unstyled-" + x.id}>
                                       <li className="init d-flex justify-content-between align-items-center">
                                          <span dangerouslySetInnerHTML={{__html:  ovv}}></span>
                                          <i className="fa fa-chevron-down"></i>
                                       </li>
                                       
                                       {optionArr.map(a => (
                                          <li className={other[i] === a.key ? "selected" : ""} key={a.key+i} data-value={a.key} onClick={(e) => checkFunOther(e, i, data.panId, item.forId, a.key, x.id)} dangerouslySetInnerHTML={{__html: a.key + ') ' + a.value}}></li>
                                       ))}
                                    </ul>

                                 </div>
                              )
                           })}

                        </div>
                     )
                  })}
               </div>
            ))}
         </div>
         <div style={{display: loading ? 'flex' : 'none', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 2002, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0, 0, 0, 0.7)'}}>
            <i className="fas fa-spinner fa-pulse" style={{color: 'white', fontSize: '50px'}}></i>
            <p style={{color: 'white'}}>Күте тұрыңыз</p>
         </div>
      </main>
      </>
   )

}



// imgStyle TEST
// const imgStyle : CSSProperties = {
//    width: 'auto', 
//    maxWidth: '100%',

//    height: 'auto',
//    // minHeight: '100px', 
//    // maxHeight: '35vh', 
//    objectFit: 'contain'
// }

// imgStyle
const imgStyle : CSSProperties = {
   width: 'auto', 
   maxWidth: '350px', 

   height: 'auto',
   minHeight: '100px', 
   maxHeight: '35vh', 
   objectFit: 'contain'
}

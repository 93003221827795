import React, { ChangeEvent, CSSProperties, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Prompt, useHistory, useParams } from "react-router-dom";

import { BASE_URL, BASE_URL_IMG } from "../../../constants";
import { removeTestAnswerItemsTeacher, setTestAnswerItemsTeacher } from "../../../redux/slices/testAnswerSlice";
import { clearTimer, setTimer, setTimerInterval } from "../../../redux/slices/timerSlice";
import { setErrorWorkData, setErrorWorkDataTeacher } from "../../../redux/slices/testSlice";
import { setConfirm } from "../../../redux/slices/confirmSlice";

import { ConfirmModal } from "../../../ui/modals/ConfirmModal";
import { getReplacedQuesStr, getTd, limitHandler } from "../../../helpers/testScript";
import '../../../customStyles/dashstyle.css'
import { AppDispatch, RootState } from "../../../redux/store";
import { FinishTestModal } from "../../../ui/modals/FinishTestModal";
import { ErigishModal, MendeleevModal } from "../../../ui/modals/MendeleevModal";
import { HelperDropdown } from "../../../ui/HelperDropdown";
import { Calculator } from "../../../ui/Calculator";
import { FinishTestTimeoutModal } from "../../../ui/modals/FinishTestTimeoutModal";

interface ParamTypes {
   testId: string
}

export const TestingTeacher = () => {
   const [loading, setLoading] = useState(false);

   const { testId } = useParams<ParamTypes>();
   const authUserId = useSelector((state : RootState) => state.auth.data?.userId);
   const testData = useSelector((state : RootState) => state.test.dataTeacher);
   const items = useSelector((state : RootState) => state.testAnswer.dataTeacher);

   const timer = useSelector((state : RootState) => state.timer)
   const confirm = useSelector((state : RootState) => state.confirm.value)

   const history = useHistory();

   const dispatch = useDispatch<AppDispatch>();



   const updateCountdown = () => {
      let now = new Date();
      let diff = new Date(timer.finishTime).valueOf() - now.valueOf();
   
      let hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((diff % (1000 * 60)) / 1000);

      if (hours <= 0 && minutes <= 0 && seconds <= 0) {
         dispatch(clearTimer());
         document.getElementById('finishTestBtn')?.click();
         return dispatch(setTimer("00:00"))
      }
      const minutesText = minutes < 10 ? `0${minutes}` : minutes;
      const secondsText = seconds < 10 ? `0${seconds}` : seconds;
      const timerText = hours !== 0
         ? `${hours}:${minutesText}:${secondsText}`
         : `${minutesText}:${secondsText}`
      
      dispatch(setTimer(timerText))
   }


   const testStartHandler = () => {
      let interval = setInterval(updateCountdown, 1000)
      dispatch(setTimerInterval({interval, panId: testData.items[0].panId}))
   }

   
   useEffect(() => {
      confirm ? testStartHandler() : history.push(`/Auth/TestWorkTeacher/${testId}`)
   }, [confirm])// eslint-disable-next-line react-hooks/exhaustive-deps


   
   const checkFun = (e : ChangeEvent<HTMLInputElement>, checkedValue : string) => {
      if(limitHandler(e)){
         // console.log('limit: false');
         const isChecked = e.target.checked; // isInputChecked | bool
         const quesNumber = parseInt(e.target.name);

         // let str : string | undefined= '';

         // if(items) {
         //    str = items.find(x => x.ForId === quesNumber) ? items.find(x => x.ForId === quesNumber)?.Otvet : ''
         // }
         let str = items.find(x => x.ForId === quesNumber) ? items.find(x => x.ForId === quesNumber)?.Otvet : ''

         // if(quesNumber > 25 && str) {
         //    if (isChecked){
         //       str += checkedValue;
         //    }
         //    else{
         //       for (let i = 0; i < str.length; i++) {
         //          if (str[i] === checkedValue) {
         //             str = str.replace(str[i], '');
         //          }
         //       }
         //    }
         // }
         // else {
         //    str = checkedValue;
         // }
         str = checkedValue;

         dispatch(setTestAnswerItemsTeacher({
            "Otvet" : str,
            "ForId" : quesNumber
         }));
      }
   }

   const testFinishHandler = async () => {
      setLoading(true);

      // console.log(items); // console
      try {
         await fetch(`${BASE_URL}/api/kval/end`, {
            method: 'POST',
            headers: {
               'Accept': 'application/json', 'Content-Type': 'application/json'
            },
            body: JSON.stringify({
               "Id": parseInt(testId),
               "UserId": authUserId,
               "Items": items
            })
         })
         .then(response => {
            if(response.ok) return response.json();
            throw new Error('*** Error finishTest() Repsponse not OK ***' + `\n responseStatus: ${response.status}, \n responseStatusText: ${response.statusText}`)
         })
         .then(result => {
            setLoading(false);
            console.log(result);
            if(!result || (typeof result === 'string')) {
               console.log(result); // console
               throw new Error('*** Error finishTest() Result is null or string ***')
            }
            else{
               window.location.reload();
               
               dispatch(setErrorWorkDataTeacher(result));
               dispatch(removeTestAnswerItemsTeacher());
               dispatch(setConfirm(false))
               
               // history.push(`/Auth/TestWork/${result.id}`);
            }
         })
      } catch (e) {
         // alert('Қателік кетті!');
         console.log(e)
      }
   }



   return (
      <>
      <Prompt when={confirm} message={(_, action) : string => {
         action === 'POP' && history.go(1)
         return 'точно баска бетке кеттің ба? Тест бітпеді ғой әлі?'
      }} />
      <main className="ttr-wrapper">
         <ConfirmModal />
         <FinishTestModal testFinish={testFinishHandler} />
         <FinishTestTimeoutModal testFinish={testFinishHandler}/>
         <div className="container-fluid">
            <div className="db-breadcrumb" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
               
               <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                  <h4 className="breadcrumb-title">“Педагогика, оқыту әдістемесі”</h4>
                  {/* <div id="timer" style={{width: '185px'}}>
                     {timer ? (
                        <>
                           <span className="time_pretext">Қалған уақыт: </span>{timer.time}
                        </>
                     ) : '...'}
                  </div> */}
               </div>
               <div
                  // style={{padding: '5px 20px'}}
                  >
                  <button
                     // onClick={() => testFinishHandler()}
                     id="myBtn"
                     style={{width: '140px', padding: '7px 0', backgroundColor: 'aquamarine', border: '2px solid #4b1867', fontSize: '15px'}}
                     data-toggle="modal"
                     data-target="#finishTestModal"
                  >
                     Тестті аяқтау
                  </button>
                  <button
                     id="finishTestBtn"
                     style={{display: 'none'}}
                     data-toggle="modal"
                     data-target="#finishTestTimeoutModal"
                  ></button>
               </div>
            </div>

            {/* <div className="tools-wrap-2 dropdown" style={{marginBottom: '15px'}}>
               <HelperDropdown/>
            </div> */}

            <div style={{overflowX: 'auto'}}>
               <table>
                  <tbody><tr>
                     {testData && testData.items.map((_, index) => (
                        <td key={(index + 1).toString()} onClick={(e) => getTd(index + 1)} className="test-td-hover">
                           {index + 1}
                        </td>
                     ))}
                  </tr><tr>
                     {testData && testData.items.map(item => (
                        <td key={'otv' + item.forId} id={'otv' + item.forId}>
                           {items ? items.find(x => x.ForId === item.forId) ? items.find(x => x.ForId === item.forId)?.Otvet : '' : ''}
                        </td>
                     ))}
                  </tr></tbody>
               </table>
            </div>

            <br />

            <div className="test-pole">
               {testData && testData.items.map((item, index) => {
                  // const type = (index + 1) > 25 ? "checkbox" : "radio";
                  const type = "radio";
                  let defaultCheckedValue : string | undefined = '';
                  if(items){
                     defaultCheckedValue = items.find(x => x.ForId === item.forId) ? items.find(x => x.ForId === item.forId)?.Otvet : ''
                  }

                  let inputArr = [
                     { key: 'A', value: item.a },
                     { key: 'B', value: item.b },
                     { key: 'C', value: item.c },
                     { key: 'D', value: item.d },
                     { key: 'E', value: item.e }
                  ]

                  return (
                     <div className="quesWrap" key={'quesWrap_' + (index+1)} id={'select' + (index + 1)} style={{display: index === 0 ? 'block' : 'none'}}>
                        <a className="test-number" id="test-number">{index + 1} - сұрақ</a>
                        {item.topUrl && 
                           <>
                              <br/>
                              <img src={BASE_URL_IMG + item.topUrl} style={imgStyle} alt="" />
                           </>
                        }
                        {item.name && <p className="test-text" dangerouslySetInnerHTML={{__html: getReplacedQuesStr(item.name)}}></p>}
                        {item.buttomUrl && <img src={BASE_URL_IMG + item.buttomUrl} style={imgStyle} alt=""/>}
                        {item.name2 && <p className="test-text" dangerouslySetInnerHTML={{__html: getReplacedQuesStr(item.name2)}}></p>}
       
                        {inputArr.map((x, i) => (
                           <div className="form_radion_btn" key={('radid' + (index + 1)) + (i+1)}>
                              <input type={type} id={('radid' + (index + 1)) + (i+1)} value={i+1} name={item.forId.toString()} onChange={(e) => checkFun(e, x.key)} defaultChecked={defaultCheckedValue?.includes(x.key)}/>
                              <label htmlFor={('radid' + (index + 1)) + (i+1)}>
                                 <span style={{paddingRight: '10px'}}>{x.key} |</span>
                                 {item.panId === 17 ? <span>{x.value}</span> : <span dangerouslySetInnerHTML={{__html: x.value}}></span>}
                              </label>
                              <br/>
                           </div>
                        ))}
                     </div>
                  )
               })}
            </div>
         </div>
         <div style={{display: loading ? 'flex' : 'none', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 2002, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0, 0, 0, 0.7)'}}>
            <i className="fas fa-spinner fa-pulse" style={{color: 'white', fontSize: '50px'}}></i>
            <p style={{color: 'white'}}>Күте тұрыңыз</p>
         </div>
      </main>
      </>
   )

}


// imgStyle TEST
// const imgStyle : CSSProperties = {
//    width: 'auto', 
//    maxWidth: '100%',

//    height: 'auto',
//    // minHeight: '100px', 
//    // maxHeight: '35vh', 
//    objectFit: 'contain'
// }

// imgStyle
const imgStyle : CSSProperties = {
   width: 'auto', 
   maxWidth: '350px', 

   height: 'auto',
   minHeight: '100px', 
   maxHeight: '35vh', 
   objectFit: 'contain'
}

import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { BASE_URL_NUSKA } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { setNuskaData } from "../../../redux/slices/nuskaSlice";



const arrNuska = [
   {id: 1, name: '3015'},
   {id: 2, name: '3016'},
   {id: 3, name: '3017'},
   {id: 4, name: '3018'},
]



export const TestNuska = () => {
   const [loading, setLoading] = useState<boolean>(false);


   const authUserId = useSelector((state : RootState) => state.auth.data?.userId);
   const nuskaData = useSelector((state : RootState) => state.nuska.data)

   const dispatch = useDispatch<AppDispatch>()


   const getNuskas = async() => {
      setLoading(true);

      await fetch(`${BASE_URL_NUSKA}/quiz/valid`, {
         method: 'POST',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
         },
         body: JSON.stringify({
            "userId": authUserId
         })
      })
      .then(response => {
         if(response.ok) return response.json();
         throw new Error('*** Error getNuskas() Response not OK ***');
      })
      .then(result => {
         // console.log(result);

         setLoading(false);

         if(!result || (typeof result === 'string')){
            console.log(result);
            throw new Error('*** Error startHandler() Result is null or string ***')
         }
         else {
            if(dispatch(setNuskaData(result))){

               // console.log()
               // history.push(`/Auth/TestAnaliz/${result.id}`);
               
            }
         }

      })

   }

   useEffect(() => {
      !nuskaData && getNuskas()
   }, [])


   return (
      <main className="ttr-wrapper">
         <div className="container-fluid">
            <div className="db-breadcrumb">
               <h4 className="breadcrumb-title">Нұсқалар</h4>
            </div>
            
            <div className="row">
               {nuskaData ? nuskaData.nuskas?.map((p, i) => {
                     let bgNumber = i + 1;

                     if(bgNumber > 5 && bgNumber < 10) bgNumber -= 5;
                     if(bgNumber > 10 && bgNumber < 15) bgNumber -= 10;
                     if(bgNumber > 15) bgNumber -= 15;

                     let bgText = "widget-bg" + bgNumber;
                     return (
                        <div key={p.id.toString()} className="col-md-6 col-lg-3 col-xl-3 col-sm-6 col-12">
                           <div className={`widget-card ${bgText}`}>
                              <Link className="wc-item" to={nuskaData.dostup ? `/Auth/TestNuskaStart/${p.id}` : '/Auth/TestNuska'}>
                                 <h4 className="wc-title">
                                    {p.number} - нұсқа
                                 </h4>
                                 <span className="wc-des" style={{color: '#fff'}}>
                                    {/* тақырып бойынша */}
                                    жаңа формат
                                 </span>
                                 <span className="wc-stats" style={{color: '#fff'}}>

                                 </span>
                                 <div className="progress wc-progress">
                                    <div className="progress-bar" role="progressbar" style={{width: '0%'}} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}>
                                    </div>
                                 </div>
                                 <span className="wc-progress-bx" style={{color: '#fff'}}>
                                    <span className="wc-change">
                                       {nuskaData.dostup ? 'Тапсыру' : <i className="fa fa-lock"></i>}
                                    </span>
                                 </span>
                              </Link>
                           </div>
                        </div>
                     )
               }) : (
                  <SkeletonTheme>
                     {new Array(1, 2, 3, 4).map(x => (
                        <div key={'skeleton_' + x.toString()} className="col-md-6 col-lg-3 col-xl-3 col-sm-6 col-12">
                           <Skeleton height={115}/>
                        </div>
                     ))}
                  </SkeletonTheme>
               )}
            </div>
         </div>
      </main>
   )
}
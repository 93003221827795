import { createSlice } from "@reduxjs/toolkit";
import { ITestHistory } from "../../helpers/models";

interface historyProps {
   data: ITestHistory | null
}
const storedData = sessionStorage.getItem('testHistoryData')
const initialState : historyProps = {
   data: typeof storedData === 'string' ? JSON.parse(storedData) : null
}

export const testHistorySlice = createSlice({
   name: 'testHistory',
   initialState,
   reducers: {
      setTestHistoryData: (state, action) => {
         state.data = action.payload;
         // sessionStorage.setItem('testHistoryData', JSON.stringify(state.data))
      }
   }
})

export const { setTestHistoryData } = testHistorySlice.actions;
export default testHistorySlice.reducer;
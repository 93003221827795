import { createSlice } from "@reduxjs/toolkit";


let newFinishTime : Date = new Date();

const setTestTime = (panId : number) => {
   // newFinishTime = new Date();
   if(panId === 4) {
      newFinishTime.setMinutes(newFinishTime.getMinutes() + 20);
   }
   else if(panId === 6 || panId === 7){
      newFinishTime.setMinutes(newFinishTime.getMinutes() + 40);
   }
   else if(panId === 555) {
      newFinishTime.setHours(newFinishTime.getHours() + 4);
      // newFinishTime.setSeconds(newFinishTime.getSeconds() + 16);
   }
   else if(panId === 55555) { // нұсқа тест
      newFinishTime.setHours(newFinishTime.getHours() + 3);
      newFinishTime.setMinutes(newFinishTime.getMinutes() + 30);
      // newFinishTime.setSeconds(newFinishTime.getSeconds() + 16);
   }
   else if(panId === 34) { // TestTeacher
      newFinishTime.setMinutes(newFinishTime.getMinutes() + 60);
      // newFinishTime.setSeconds(newFinishTime.getSeconds() + 16);
   }
   else {
      newFinishTime.setMinutes(newFinishTime.getMinutes() + 90);
      // newFinishTime.setSeconds(newFinishTime.getSeconds() + 16);
   }
   newFinishTime.setSeconds(newFinishTime.getSeconds() + 2);
}

interface TimerTest {
   isRunning: boolean
   finishTime: Date
   interval: number | null
   time: string
}

const storedFinishTime = sessionStorage.getItem('storeTime');
const initialState : TimerTest = {
   isRunning: false,
   finishTime: typeof storedFinishTime === 'string' ? JSON.parse(storedFinishTime) : newFinishTime,
   interval: null,
   time: '_:_:_'
}

export const timerSlice = createSlice({
   name: 'timer',
   initialState,
   reducers: {
      setTimerInterval: (state, action) => {
         state.interval = action.payload.interval;
         setTestTime(action.payload.panId);
      },
      setTimer: (state, action) => {
         if(!state.isRunning){
            state.isRunning = true;
            sessionStorage.setItem('storeTime', JSON.stringify(state.finishTime))
         }
         state.time = action.payload;
      },
      clearTimer: (state) => {
         // console.log(state);
         // state.isRunning = false;
         sessionStorage.removeItem('storeTime');
         state.isRunning = false;
         clearInterval(!state.interval ? 0 : state.interval)
         clearInterval(!state.interval ? 0 : state.interval - 1)
      }
   }
})

export const { setTimer, clearTimer, setTimerInterval } = timerSlice.actions;
export default timerSlice.reducer;